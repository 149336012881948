import React from 'react';

const ProjectDetails = ({ title, description }) => (
  <div className="text-left sm:space-y-4 sm:max-w-sm">
    <h2
      className="xl:text-5xl lg:text-4xl md:text-3xl sm:text-2xl text-[23px] font-montserrat font-medium lg:text-center lg:ml-10 lg:w-52 xl:w-full"
      style={{ textShadow: '3px 3px 4px rgba(0, 0, 0, 0.25)' }}
    >
      {title}
    </h2>
    <p className="w-3/4 xl:w-full lg:text-xs md:text-[10px] sm:text-[8px] text-[6px] font-montserrat text-gray-700 lg:text-center xl:ml-8">
      {description}
    </p>
  </div>
);

export default ProjectDetails;

// TechofestIntro.jsx
import React from 'react';
import DecorativeGraphics from './decorative-graphics';

const TechofestIntro = () => {
  return (
    <section className="bg-[#faf8ef] min-h-screen py-16 px-8 relative overflow-hidden">
      <DecorativeGraphics />
      <div className="flex justify-center items-center text-center min-h-screen">
        <p className="text-2xl text-center text-gray-800 leading-relaxed font-montserrat w-[60%]">
        Techofest 2024 bertujuan untuk memajukan inovasi digital dalam bisnis dan kehidupan sehari-hari dengan menonjolkan penerapan kecerdasan buatan (AI). Fokus kami adalah bagaimana AI dapat meningkatkan efisiensi, mempermudah proses, dan membuka peluang baru. Dalam acara ini, kami akan memamerkan proyek-proyek unggulan yang menunjukkan solusi praktis dari teknologi canggih, mulai dari mempermudah pembuatan skripsi hingga memperkenalkan inovasi mata uang digital yang revolusioner.
        </p>
      </div>
    
      <div className="absolute bottom-16 right-8 text-5xl tracking-wider font-crushed text-right">
        Our Journey Begins
      </div>
    </section>
  );
};

export default TechofestIntro;

import React from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

const NavigationButtons = ({ onNext, onPrevious }) => {
  return (
    <div className="flex space-x-4">
      <button
        className="p-3 bg-black text-white rounded-full hover:scale-105 transition"
        onClick={onPrevious}
      >
        <FaArrowLeft />
      </button>
      <button
        className="p-3 bg-black text-white rounded-full hover:scale-105 transition"
        onClick={onNext}
      >
        <FaArrowRight />
      </button>
    </div>
  );
};

export default NavigationButtons;

import React from 'react';
import technolimit from '../../../assets/images/landing-page/techofest-years/technolimit.png';
import DecorativeGraphics from './decorative-graphics'; 

const AboutTemplate = ({ title, text, image }) => {
  return (
    <section className="bg-[#faf8ef] min-h-screen py-16 px-8 relative overflow-hidden">
      {/* Header with Title */}
      <div className="max-w-6xl mx-auto text-center mb-12">
        <h1 className="text-8xl font-crushed">{title}</h1>
      </div>

      {/* Main Content */}
      <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* Left Column: Text Content */}
        <div className="space-y-6">
          <p className="mt-0 ml-[10%] text-2xl text-black-800 leading-relaxed font-montserrat w-[80%]">
            {text}
          </p>
        </div>

        {/* Right Column: Image */}
        <div className="relative z-10 pb-0">
          <img
            src={image}
            alt="Dynamic Content"
            className="rounded-lg"
          />
        </div>
      </div>

      {/* Decorative Graphics */}
      <DecorativeGraphics />
    </section>
  );
};

export default AboutTemplate;

import React from 'react';

const ProjectSlider = ({ images, activeIndex, setActiveIndex }) => {
  const nextImage = () => setActiveIndex((prev) => (prev + 1) % images.length);
  const prevImage = () =>
    setActiveIndex((prev) => (prev - 1 + images.length) % images.length);

  return (
    <div className="relative ml-0 right-[-40px] sm:right-[0] w-[200px] min-h-[100px] sm:w-[350px] sm:h-[150px] md:w-[350px] md:h-[300px] xl:w-[538px] xl:h-[534px] lg:w-[400px] lg:h-[434px] z-[3]">
      <img
        src={images[activeIndex]}
        alt={`Project ${activeIndex + 1}`}
        className="absolute right-[17%] w-full h-full object-contain sm:object-cover rounded-lg"
      />
    </div>
  );
};

export default ProjectSlider;

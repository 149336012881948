import React from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import DecorativeGraphics from "./decorative-graphics";
import InnovativeBlast from "../../../assets/images/landing-page/techofest-years/innovationBlast.png";
import Technolimit from "../../../assets/images/landing-page/techofest-years/technolimit.png";
import TechofestLogo from "../../../assets/images/landing-page/hero-section/techofest-logo.png";

const TimelineSection = () => {
  const navigate = useNavigate(); // Initialize useNavigate

  const handleLearnMore = () => {
    navigate("/grand-project-story"); // Navigate to Grand Project Story
  };

  return (
    <section className="relative ml-16 mx-auto pb-48">
      <DecorativeGraphics />

      <div className="max-w-6xl mx-auto relative h-[400px]">
        <div className="absolute left-20 top-0 w-48">
          <img
            src={InnovativeBlast}
            alt="Innovation 2022"
            className="w-full h-full object-cover rounded-[15px]"
          />
        </div>

        <div className="absolute left-80 top-0 w-24">
          <img
            src={TechofestLogo}
            alt="Techofest Logo"
            className="w-full h-full object-cover rounded-[15px]"
          />
        </div>

        <div className="absolute right-20 top-0 w-[480px] h-[430px]">
          <img
            src={Technolimit}
            alt="Technolimit 2023"
            className="w-full h-full object-cover rounded-[45px]"
          />
        </div>

        <div className="absolute text-center left-24 top-[40%]">
          <p className="text-base leading-9 text-black-100 max-w-[40%] font-montserrat">
            Bergabunglah dengan kami di Techofest 2024 dan jadilah bagian dari perubahan digital yang bermanfaat dan menguntungkan!
          </p>
        </div>

        <div className="absolute bottom-[-20%] left-[0]">
          <button
            onClick={handleLearnMore} // Navigate on click
            className="mt-8 px-6 py-2 bg-black text-white rounded-full hover:scale-105 transition"
          >
            Learn More
          </button>
        </div>
      </div>
    </section>
  );
};

export default TimelineSection;

import React from 'react';

const Preview = ({ pic1, pic2, pic3, pic4 }) => {
  return (
    <div className="grid grid-cols-2 grid-rows-5 justify-center gap-x-8 gap-y-20 max-w-4xl mx-auto h-full mt-32">
      <img src={pic1} alt="pic1" className="col-start-1 row-span-2 w-[400px] h-[400px] rounded-lg object-cover z-20" />
      <img src={pic2} alt="pic2" className="col-start-2 row-start-2 row-span-2 w-[400px] h-[400px] rounded-lg object-cover z-20" />
      <img src={pic3} alt="pic3" className="col-start-1 row-start-3 row-span-2 w-[400px] h-[400px] rounded-lg object-cover z-20" />
      <img src={pic4} alt="pic4" className="col-start-2 row-start-4 row-span-2 w-[400px] h-[400px] rounded-lg object-cover z-20" />
    </div>
  );
};

export default Preview;

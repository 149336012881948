// navbar.jsx
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import unityLogo from '../../../assets/images/landing-page/hero-section/unity-logo.png';
import techofestLogo from '../../../assets/images/landing-page/hero-section/techofest-logo.png';
import { FaBars, FaTimes } from 'react-icons/fa';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(prev => !prev);
    console.log('Menu Toggled:', !isOpen);
  };

  return (
    <header className="w-full max-w-7xl mx-auto relative mb-0">
      <div className="flex items-center justify-between py-4 px-8">
        <div className="flex items-center space-x-6">
          <div className="flex items-center space-x-2">
            <img src={unityLogo} alt="Unity Logo" className="w-16 h-16" />
            <img src={techofestLogo} alt="Techofest Logo" className="w-16 h-16" />
          </div>
          <div className="w-[2px] h-8 bg-gray-300 hidden sm:block"></div>

          {/* Desktop Navigation Links */}
          <nav className="hidden sm:flex items-center space-x-6 text-lg font-montserrat">
            <NavLink
              to="/"
              className={({ isActive }) =>
                isActive ? 'text-blue-600 underline' : 'text-gray-800 hover:underline'
              }
            >
              Beranda
            </NavLink>
            <NavLink
              to="/about"
              className={({ isActive }) =>
                isActive ? 'text-blue-600 underline' : 'text-gray-800 hover:underline'
              }
            >
              Tentang Kami
            </NavLink>
          </nav>
        </div>

        {/* Hamburger Menu Button for Mobile */}
        <div className="sm:hidden">
          <button
            onClick={toggleMenu}
            aria-label="Toggle Menu"
            className="cursor-pointer"
          >
            {isOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
          </button>
        </div>

        <button className="hidden sm:block px-4 py-2 border rounded-full border-gray-400 hover:bg-gray-100">
          Login
        </button>
      </div>

      {/* Mobile Menu */}
      {isOpen && (
        <div className="fixed inset-0 bg-blue-600 text-white z-50 p-10">
          <button
            onClick={toggleMenu}
            className="absolute top-4 right-4 cursor-pointer"
            aria-label="Close Menu"
          >
            <FaTimes size={24} />
          </button>
          <nav className="flex flex-col items-center justify-center h-full space-y-8 text-lg font-montserrat">
            <NavLink
              to="/"
              onClick={toggleMenu}
              className={({ isActive }) =>
                isActive ? 'bg-blue-700 px-4 py-2 rounded-md' : 'hover:bg-blue-700 px-4 py-2 rounded-md'
              }
            >
              Beranda
            </NavLink>
            <NavLink
              to="/about"
              onClick={toggleMenu}
              className={({ isActive }) =>
                isActive ? 'bg-blue-700 px-4 py-2 rounded-md' : 'hover:bg-blue-700 px-4 py-2 rounded-md'
              }
            >
              Tentang Kami
            </NavLink>
            <button className="w-1/2 text-center py-2 bg-white text-blue-600 rounded-full hover:bg-gray-200">
              Login
            </button>
          </nav>
        </div>
      )}

      <div className="absolute bottom-0 left-12 right-12 h-[2px] bg-gray-300"></div>
    </header>
  );
};

export default Navbar;

import React from "react";
import redCircle from "../../assets/images/landing-page/hero-section/red-circle.png";
import blueCircle from "../../assets/images/landing-page/hero-section/half-circle-blue.png";
import greenCircle from "../../assets/images/landing-page/hero-section/half-circle-green.png";
import skyblueCircle from "../../assets/images/projects/skybluecircle.png";
import yellowCircle from '../../assets/images/landing-page/hero-section/yellow-circle.png';
import darkgreenCircle from '../../assets/images/projects/greencircle.png';
import orangeCircle from '../../assets/images/projects/orangecircle.png';
import stripSatria from '../../assets/images/projects/strip-satria.png';
import stripClarissa from '../../assets/images/projects/strip-clarissa.png';
import stripJeremy from '../../assets/images/projects/strip-jeremy.png';
import triangleRight from '../../assets/images/landing-page/futurescape/triangle-right.png';

const DecorativeGraphics = () => {
  return (
    <>
        {/* Red Circle with Green Dot */}
        <div className="absolute bottom-[26%] right-16 w-[14%] flex items-center justify-center z-0">
            <img src={redCircle} alt="Red Circle" className="w-full h-full" />

            {/* Green Dot */}
            <div className="absolute w-6 h-6 bg-green-500 rounded-full border-2 border-white shadow-md"></div>
        </div>

        {/* sky blue ircle */}
        <img
        src={skyblueCircle}
        className="absolute w-32 top-[11%] left-[35%] rotate-45"
        />
        {/* green circle */}
        <img
        src={greenCircle}
        className="absolute w-16 top-[10%] right-[0]"
        />
        {/* blue circle */}
        <img
        src={blueCircle}
        className="absolute w-28 top-[55%] right-[34%] -rotate-90"
        />
        {/* darkgreen circle */}
        <img
        src={darkgreenCircle}
        className="absolute w-28 bottom-[21%] left-[36%]"
        />
        {/* yellow circle */}
        <img src={yellowCircle}
        className="absolute w-20 bottom-[6.5%] right-[8.5%]"/>
        {/* orange circle */}
        <img src={orangeCircle}
        className="absolute w-20 bottom-[4%] left-[9%]"/>
        {/* strip satria */}
        <img src={stripSatria}
        className="absolute w-[14%] h-[20%] top-[23%] right-[25%]"/>
        {/* Strip line + triangle */}
        <div className="absolute top-[7%] left-[12.5%] z-10">
            <img
            src={stripClarissa}
            alt="Strip Line"
            className="z-10 w-[250px] h-[560px]"
            />
            <img
            src={triangleRight}
            alt="Triangle Right"
            className="absolute top-[-15px] left-[270px] w-6 rotate-180"
            />
        </div>
        {/* Strip line + triangle */}
        <div className="absolute bottom-[9%] right-[10.7%] z-10">
            <img
            src={stripJeremy}
            alt="Strip Line"
            className="z-10 w-[20px] h-[350px]"
            />
        </div>

        {/* yellow Circle with blue Dot */}
        <div className="absolute top-[38%] left-[7%] w-[12%] flex items-center justify-center z-0">
            <img src={yellowCircle} alt="yellow Circle" className="w-full h-full" />

            {/* blue Dot */}
            <div className="absolute w-6 h-6 bg-blue-700 rounded-full border-2 border-white shadow-md"></div>
        </div>
    </>
  );
};

export default DecorativeGraphics;

import React, { useEffect } from 'react';
import AboutTemplate from '../../about/techofest-about/about-template';
import grandProjectImage from '../../../assets/images/grand-project-story/gp-picture.png';
import IntroTemplate from '../../about/techofest-intro/intro-template';
import Gallery from './gallery-2023';

const GPStory2023 = () => {
  const title = 'Grand Project Story';
  const text = `Di balik kesuksesan Techofest 2023, ada tim yang bekerja keras untuk
    mewujudkan visi besar kami dalam mengembangkan setiap proyek ini. Setiap langkah 
    yang diambil adalah hasil dari dedikasi dan kreativitas tanpa henti. Tim Grand Project 
    terdiri dari berbagai mahasiswa-mahasiswi brilian Fakultas Teknik UNSRAT yang telah 
    mengorbankan waktu, tenaga, dan pikiran mereka untuk menciptakan solusi teknologi kreatif 
    dan inovatif.`;

  const content = `Perjuangan membangun ReportFormatter dan Spark bukanlah perjalanan yang mudah. Mereka memulai dari nol, menghadapi tantangan teknis, keterbatasan sumber daya, dan kompleksitas pengembangan kecerdasan buatan. Namun, dengan semangat kolaborasi dan komitmen tinggi, mereka terus mencari solusi, belajar dari kesalahan, dan beradaptasi dengan cepat. Techofest 2023 menjadi bukti bahwa dengan tekad, semangat inovasi, dan kerja keras, kita dapat menciptakan teknologi yang tidak hanya memecahkan masalah hari ini, tetapi juga membuka jalan bagi masa depan yang lebih baik.`;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <AboutTemplate 
        title={title}
        text={text}
        image={grandProjectImage}
      />
      
      <IntroTemplate 
        content={content}
        targetPage={{ techofest2023: "/gp-story2023", techofest2024: "/grand-project-story" }}
      />

      <Gallery />
    </div>
  );
};

export default GPStory2023;

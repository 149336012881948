import React from 'react';
import { FaPlay } from 'react-icons/fa';

const SliderNavigationButton = ({ direction, onClick }) => (
  <div className="mb-14">
    <button
      onClick={onClick}
      className="w-[30px] h-[30px] sm:w-12 sm:h-12 rounded-full border-2 border-blue-600 bg-white flex items-center justify-center transition hover:scale-105"
      aria-label={`Go ${direction}`}
    >
      <FaPlay
        className={`text-blue-600 ${
          direction === 'left' ? 'rotate-180' : ''
        } ${getIconSize()}`}
      />
    </button>
  </div>
);

// Helper function to dynamically assign size classes based on screen size
const getIconSize = () => {
  if (window.innerWidth >= 640) return 'w-6 h-6'; 
  return 'w-3 h-3'; // Default size for smaller screens
};

export default SliderNavigationButton;

import React from 'react';
import softBlueStar from '../../../assets/images/landing-page/hero-section/soft-blue-star.png';
import stripLineLeft from '../../../assets/images/about/strip-gallery.png';
import stripLineRight from '../../../assets/images/landing-page/futurescape/strip-lines-right.png';
import triangleLeft from '../../../assets/images/landing-page/futurescape/triangle-left.png';
import triangleRight from '../../../assets/images/landing-page/futurescape/triangle-right.png';
import yellowCircle from '../../../assets/images/landing-page/hero-section/yellow-circle.png';
import blueHalfCircle from '../../../assets/images/landing-page/hero-section/half-circle-blue.png';
import greenCircle from '../../../assets/images/about/greenCircle.png';
import redCircle from '../../../assets/images/landing-page/hero-section/red-circle.png';

const DecorativeGraphics = () => (
  <div className="absolute inset-0 pointer-events-none z-0">
    <div>
        {/* Left Strip Line with Triangle */}
        <img
        src={stripLineLeft}
        alt="Strip Line Left"
        className="absolute bottom-[56px] left-[60px] z-10"
        />
        <img
        src={triangleLeft}
        alt="Triangle Left"
        className="absolute bottom-[45px] left-[600px] w-6"
        />
    </div>
    {/* Right Strip Line with Triangle */}
    <img
      src={stripLineRight}
      alt="Strip Line Right"
      className="absolute top-[110px] right-[68px] z-[1] w-[450px]"
    />
    <img
      src={triangleRight}
      alt="Triangle Right"
      className="absolute top-[100px] right-[530px] w-6"
    />

    {/* Soft Blue Star */}
    <img
      src={softBlueStar}
      alt="Soft Blue Star"
      className="absolute top-0 left-[485px] w-6"
    />

    {/* Blue Half Circle */}
    <img
        src={blueHalfCircle}
        alt="Blue Half Circle"
        className="absolute bottom-4 right-[35%] w-36 h-16"
      />

    {/* Green Circle with Blue Dot */}
    <div className="absolute 
    top-[8%] left-0 w-36
    flex items-center justify-center">
        <img src={greenCircle} alt="Green Circle" className='w-full h-full'/>
    </div>

    {/* Yellow Circle with Red Dot */}
    <div className="absolute 
    bottom-[15%] right-0 w-36
    flex items-center justify-center">
        <img src={yellowCircle} alt="Yellow Circle" className="w-full h-full" />
        {/* Red Dot */}
        <div className="absolute w-6 h-6 bg-red-500 rounded-full border-2 border-white shadow-md">
        </div>
    </div>

    {/* Red Circle with Yellow Dot */}
    <div className="absolute 
    bottom-[13%] left-[-0.5%] w-36
    flex items-center justify-center">
        <img src={redCircle} alt="Red Circle" className="w-full h-full" />
        {/* Yellow Dot */}
        <div className="absolute w-6 h-6 bg-yellow-500 rounded-full border-2 border-white shadow-md">
        </div>
    </div>
  </div>
);

export default DecorativeGraphics;

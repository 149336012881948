// TeamSection.jsx
import React from 'react';
import Satria from '../../assets/images/projects/satria.png';
import Clarissa from '../../assets/images/projects/clarissa.png';
import Albert from '../../assets/images/projects/albert.png';
import Galnoel from '../../assets/images/projects/galnoel.png';
import Faithful from '../../assets/images/projects/faithful.png';
import Graphics from './graphics';

const teamMembers = [
  { name: 'Satria Amu', role: 'Project Leader', image: Satria }, // Position 2
  { name: 'Clarissa Maramis', role: 'Scrum Master', image: Clarissa }, // Position 4
  { name: 'Albert Mangiri', role: 'CET', image: Albert }, // Position 5
  { name: 'Jeremy Wowiling', role: 'Techoverse', image: '' }, // Position 6
  { name: 'Patrick Gustavo', role: 'Report Formatter', image: '' }, // Position 7
  { name: 'Galnoel Rindengan', role: 'Spark', image: Galnoel }, // Position 8
  { name: 'Faithful Mandagi', role: 'Crypnity', image: Faithful }, // Position 9
];

const TeamSection = () => {
  return (
    <div className="relative w-full flex flex-col items-center mt-16 mb-32">
      <Graphics />
      <h1 className="text-8xl font-crushed my-16">Project Team</h1>

      {/* Team Members Grid */}
      <div className="grid grid-cols-3 gap-y-20 gap-x-10">
        {/* First Row: Empty - Satria - Empty */}
        <div></div> {/* Empty spot at position 1 */}
        <div className="flex flex-col items-center z-20">
          <div className="flex items-end justify-center bg-white rounded-lg shadow-md w-[320px] h-[350px] mb-4">
            <img
              src={Satria}
              alt="Satria Amu"
              className="w-62 h-80 rounded-lg object-cover"
            />
          </div>
          <div className="flex flex-col items-center">
            <h2 className="text-xl font-semibold">Satria Amu</h2>
            <p className="text-gray-600">Project Leader</p>
          </div>
        </div>
        <div></div> {/* Empty spot at position 3 */}

        {/* Second Row: Clarissa - Albert - Jeremy */}
        {teamMembers.slice(1, 4).map((member, index) => (
          <div key={index} className="flex flex-col items-center z-10">
            <div className="flex items-end justify-center bg-white rounded-lg shadow-md w-[320px] h-[350px] mb-4">
              <img
                src={member.image}
                alt={member.name}
                className="w-62 h-80 rounded-lg object-cover"
              />
            </div>
            <div className="flex flex-col items-center">
              <h2 className="text-xl font-semibold">{member.name}</h2>
              <p className="text-gray-600">{member.role}</p>
            </div>
          </div>
        ))}

        {/* Third Row: Patrick - Galnoel - Faithful */}
        {teamMembers.slice(4).map((member, index) => (
          <div key={index} className="flex flex-col items-center z-10">
            <div className="flex items-end justify-center bg-white rounded-lg shadow-md w-[320px] h-[350px] mb-4">
              <img
                src={member.image}
                alt={member.name}
                className="w-62 h-80 rounded-lg object-cover"
              />
            </div>
            <div className="flex flex-col items-center">
              <h2 className="text-xl font-semibold">{member.name}</h2>
              <p className="text-gray-600">{member.role}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TeamSection;

import React from 'react';
import twoGreencircles from '../../../assets/images/landing-page/our-project/two-green-circles.png';
import softBlueStar from '../../../assets/images/landing-page/hero-section/soft-blue-star.png';
import softOrangeStar from '../../../assets/images/landing-page/hero-section/soft-orange-star.png';
import softGreenStar from '../../../assets/images/landing-page/hero-section/soft-green-star.png';
import hardGreenStar from '../../../assets/images/landing-page/hero-section/hard-green-star.png';
import softToscaStar from '../../../assets/images/landing-page/hero-section/soft-tosca-star.png';
import bigSoftOrangeStar from '../../../assets/images/landing-page/hero-section/big-soft-orange-star.png';
import greenishStar from '../../../assets/images/landing-page/our-project/greenish-star.png';
import blueCircle from '../../../assets/images/landing-page/our-project/blue-circle.png';
import yellowCircle from '../../../assets/images/landing-page/hero-section/yellow-circle.png';
import redCircle from '../../../assets/images/landing-page/hero-section/red-circle.png';
import stripRight from '../../../assets/images/landing-page/our-project/strip-lines-right.png';
import stripLeft from '../../../assets/images/landing-page/our-project/strip-lines-left.png';

const DecorativeGraphics = () => (
  <>
    {/* Right Strip Line */}
    <img
      src={stripRight}
      alt="Strip Right"
      className="
        absolute hidden md:block
        md:bottom-[280px] md:right-[70px] md:w-[330px] md:h-[155px] 
        lg:bottom-[190px] lg:right-[68px] lg:w-[490px] lg:h-[250px] 
        xl:bottom-[11%] xl:right-[60px] xl:w-[625px] xl:h-[270px] 
        z-[3]"
    />


    {/* Left Strip Line */}
    <img
        src={stripLeft}
        alt="Strip Left"
        className="absolute hidden md:block md:top-[15%] md:left-[20%] lg:top-[12%] lg:left-[25%] xl:top-[14%] xl:left-[23%] xl:w-36 xl:h-48 lg:w-24 lg:h-32 w-20 h-20"
      />

    {/* Two Green Circles */}
    <img
        src={twoGreencircles}
        alt="Two Green Circles"
        className="absolute xl:top-[14%] xl:left-[21%] xl:w-20 xl:h-24 lg:top-[12%] lg:left-[23%] lg:w-16 lg:h-20 md:top-[13%] md:left-[19%] top-[12%] left-[17%] w-12 h-16"
      />

    {/* Greenish Star */}
    <img 
      src={greenishStar} 
      alt="Greenish Star" 
      className="absolute top-[19%] left-[65%] w-7" 
    />

    {/* Soft Blue Star */}
    <img 
      src={softBlueStar} 
      alt="Soft Blue Star" 
      className="absolute bottom-[40%] md:bottom-[25%] left-[18%] lg:bottom-[15%] w-12" 
    />

    {/* Soft Orange Star */}
    <img 
      src={softOrangeStar} 
      alt="Soft Orange Star" 
      className="absolute lg:bottom-0 md:bottom-40 bottom-72 lg:right-[15%] right-36 w-10" 
    />

    {/* Soft Green Star */}
    <img 
      src={softGreenStar} 
      alt="Soft Green Star" 
      className="absolute top-[25%] right-[20%] w-8" 
    />

    {/* Hard Green Star */}
    <img 
      src={hardGreenStar} 
      alt="Hard Green Star" 
      className="absolute top-[6%] left-[39%] w-6" 
    />

    {/* Soft Tosca Starer */}
    <img 
      src={softToscaStar} 
      alt="Soft Tosca Star" 
      className="absolute top-[28%] xl:left-[30%] left-[40%] w-6" 
    />

    {/* Soft Orange Star */}
    <img 
      src={bigSoftOrangeStar} 
      alt="Soft Orange Star" 
      className="absolute xl:top-[50%] left-[35%] top-[60%] w-6" 
    />

    {/* Blue Circle */}
    <img 
      src={blueCircle} 
      alt="Blue Circle" 
      className="absolute md:top-[10%] lg:right-[1%] md:right-[4%] top-[18%] right-[15%] lg:w-52 md:w-36 w-16 z-[1]" 
    />

    {/* Yellow Circle */}
    <img 
      src={yellowCircle} 
      alt="Yellow Circle" 
      className="absolute bottom-[45%] lg:bottom-[29%] left-[7%] w-16" 
    />

   {/* Red Circle with Yellow Dot */}
   <div className="absolute 
   xl:bottom-[1%] lg:bottom-[13%] lg:right-[45%] 
   md:bottom-[27%] md:right-[40%] md:w-36 lg:w-44 xl:w-44 
   bottom-[47%] right-[43%] w-20
   flex items-center justify-center">
        <img src={redCircle} alt="Yellow Circle" className="w-full h-full" />

        {/* Yellow Dot */}
        <div className="absolute w-5 h-5 md:w-10 md:h-10 xl:w-6 xl:h-6 bg-yellow-500 rounded-full border-2 border-white shadow-md z-[5]"></div>
    </div>
  </>
);

export default DecorativeGraphics;

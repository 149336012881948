import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/landing-page/hero/navbar.jsx';
import Hero from './components/landing-page/hero/hero-section.jsx';
import Graphics from './components/landing-page/hero/graphics.jsx';
import OurProject from './components/landing-page/our-project/our-project.jsx';
import Futurescape from './components/landing-page/futurescape/futurescape.jsx';
import TechofestSection from './components/landing-page/techofest-member/techofest-member.jsx';
import GPSection from './components/landing-page/gp-member/gp-member.jsx';
import TimelineSection from './components/landing-page/techofest-years/timeline-section.jsx';
import Footer from './components/landing-page/footer/footer.jsx';
import About from './components/about/about.jsx';
import GrandProjectStory from './components/grand-project-story/grand-project-story.jsx';
import Project from './components/projects/projects.jsx';
import Crypnity from './components/gp-details/crypnity.jsx';
import Spark from './components/gp-details/spark.jsx';
import Techoverse from './components/gp-details/techoverse.jsx';
import ReportFormatter from './components/gp-details/reportformatter.jsx';
import GPStory2023 from './components/2023/gp-story/gp-story2023.jsx';
import Gallery2023 from './components/2023/gp-story/gallery-2023.jsx';
import Project2023 from './components/2023/projects/projects-2023.jsx';
import Techoverse2023 from './components/2023/gp-details/techoverse-2023.jsx';
import ReportFormatter2023 from './components/2023/gp-details/rf-2023.jsx';

const App = () => {
  return (
    <Router>
      <div className='bg-[#faf8ef]'>
        <Navbar />
        <Routes>
          <Route
            path="/"
            element={
              <>
                {/* Hero Section */}
                <div className="min-h-screen bg-[#faf8ef] relative">
                  <div className="relative">
                    <Hero />
                  </div>
                  <div className="absolute inset-0 z-10">
                    <Graphics />
                  </div>
                </div>
                <OurProject />
                <Futurescape />
                <TechofestSection />
                <GPSection />
                <TimelineSection />
              </>
            }
          />
          <Route path="/about" element={<About />} /> 
          <Route path="/grand-project-story" element={<GrandProjectStory />} />
          <Route path="/project/:project?" element={<Project />} />
          <Route path="/crypnity" element={<Crypnity />} />
          <Route path="/spark" element={<Spark />} /> 
          <Route path="/techoverse" element={<Techoverse />} /> 
          <Route path="/reportformatter" element={<ReportFormatter />} /> 
          <Route path="/gp-story2023" element={<GPStory2023 />} />
          <Route path="/gallery-2023" element={<Gallery2023 />} />
          <Route path="/projects-2023/:project" element={<Project2023 />} />
          <Route path="/techoverse-2023" element={<Techoverse2023 />} /> 
          <Route path="/rf-2023" element={<ReportFormatter2023 />} /> 
        </Routes>
        <Footer />
      </div>
    </Router>
  );
};

export default App;

import React from 'react';
import dekanImage from '../../../assets/images/landing-page/futurescape/dekan.png';

const SpeakerCard = ({ isBlurred }) => {
  return (
    <div
      className={`relative bg-white shadow-md p-8 flex ${
        isBlurred ? 'backdrop-blur-sm grayscale' : 'ml-auto mr-auto'
      }`}
      style={{
        width: '85%', // Responsive width as a percentage
        height: '535px',
        borderRadius: '50px', // Fully rounded border
      }}
    >
      <div className="flex-1 max-w-[60%] flex flex-col items-center justify-center h-full">
        <h2 className="text-5xl font-bold mb-4 text-center font-montserrat">
          Prof. Dr. Ir. Fabian J. Manoppo, M.Agr
        </h2>
        <p className="text-3xl text-gray-500 text-center font-montserrat">
          Dean of the Faculty of Engineering
        </p>
      </div>

      <div className="absolute bottom-0 right-8">
        <img
          src={dekanImage}
          alt="Prof. Fabian"
          className="w-[400px] h-[430px] object-cover"
        />
      </div>
    </div>
  );
};

export default SpeakerCard;

// src/components/landing-page/techofest-member/StripBelowCard.jsx
import React from 'react';
import PersonCard from './person-card';
import stripBelow from '../../../assets/images/landing-page/techofest-members/strip-below.png';

const StripBelowCard = ({ name, role, image }) => {
  return (
    <div className="relative flex flex-col items-center w-80 h-[520px]">
      <PersonCard name={name} role={role} image={image} />
      <div className="absolute -bottom-[21%] right-[-3%] w-44 h-44">
        <img
          src={stripBelow}
          alt="strip-below"
          className="w-full h-full object-contain"
        />
    </div>
  </div>

  );
};

export default StripBelowCard;

import React from "react";
import yellowCircle from '../../assets/images/landing-page/hero-section/yellow-circle.png';
import blueCircles from '../../assets/images/landing-page/hero-section/blue-circles-techofest.png';
import stripLineTop from '../../assets/images/gp-details/strip-line-top.png';
import stripPreview from '../../assets/images/gp-details/strip-preview.png';
import greenCircle from '../../assets/images/gp-details/grrencircle.png';
import grayStar from '../../assets/images/about/graystar.png';
import greenishStar from '../../assets/images/landing-page/our-project/greenish-star.png';
import halfBlueCircle from '../../assets/images/landing-page/hero-section/half-circle-blue.png';
import redCircle from "../../assets/images/landing-page/hero-section/red-circle.png";
import triangleRight from '../../assets/images/landing-page/futurescape/triangle-right.png';
import skybluecircle from '../../assets/images/projects/skybluecircle.png';
import orangeCircle from '../../assets/images/about/orangeCircle.png';
import softGreenStar from '../../assets/images/landing-page/hero-section/soft-green-star.png';
import bigSoftOrangeStar from '../../assets/images/landing-page/hero-section/big-soft-orange-star.png';
import softBlueStar from '../../assets/images/landing-page/hero-section/soft-blue-star.png';
import softToscaStar from '../../assets/images/landing-page/hero-section/soft-tosca-star.png';
import darkBlueCircle from'../../assets/images/gp-details/darkbluecircle.png';
import stripRed from '../../assets/images/gp-details/stripred.png';
import stripdevlead from '../../assets/images/gp-details/stripdevlead.png';
import stripblue from '../../assets/images/gp-details/stripblue.png';

const RFGraphics = () => {
  return (
    <>
        {/* Title and Desc */}
        {/* Yellow Circle with Red Dot */}
        <div className="absolute top-[2.8%] left-[9%] w-[11%] flex items-center justify-center">
            <img src={yellowCircle} alt="Yellow Circle" className="w-full h-full" />
            {/* Red Dot */}
            <div className="absolute w-6 h-6 bg-red-500 rounded-full border-2 border-white shadow-md"></div>
        </div>
        {/* Strip Line Top */}
        <div className="absolute top-[5.5%] left-[14.3%] w-56 z-10">
            <img src={stripLineTop}/>
        </div>
        {/* Green Circle */}
        <div>
            <img src={greenCircle}
             className="absolute top-[2%] right-0 w-32 z-10"/>
        </div>
        {/* Blue Circles */}
        <div>
            <img src={blueCircles}
             className="absolute top-[18%] right-[30%] w-40 z-10"/>
        </div>
        {/* Gray Star */}
        <div>
            <img src={grayStar}
             className="absolute top-[13%] left-[45%] w-6 z-10"/>
        </div>
        {/* Greenish star */}
        <div>
            <img src={greenishStar}
             className="absolute top-[15%] right-72 w-6 z-10"/>
        </div>

        {/* Preview */}
        {/* half blue circle */}
        <div>
            <img src={halfBlueCircle}
             className="absolute top-[24%] left-72 w-48 z-20"/>
        </div>
        {/* red Circle with blue Dot */}
        <div className="absolute top-[29%] right-24 w-[17%] flex items-center justify-center">
            <img src={redCircle} alt="red Circle" className="w-full h-full" />
            {/* Red Dot */}
            <div className="absolute w-8 h-8 bg-blue-500 rounded-full border-2 border-white shadow-md"></div>
        </div>
        {/* Strip Preview */}
        <div className="absolute top-[33.7%] right-[14.2%] z-20">
            {/* Strip Line */}
            <img
                src={stripPreview}
                className="w-2 h-56 z-20"
                alt="Strip Line"
            />
            {/* Triangle with a slight left offset */}
            <div className="relative z-20" style={{ left: '-5px' }}> {/* Adjust 'left' as needed */}
                <img
                src={triangleRight}
                alt="Triangle Right"
                className="w-5 -rotate-90"
                />
            </div>
        </div>
        {/* sky blue circle */}
        <div>
            <img src={skybluecircle}
             className="absolute top-[39%] right-[10.7%] w-32 z-10"/>
        </div>
        {/* Yellow Circle*/}
        <div className="absolute top-[36%] left-[38%] w-[8%] flex items-center justify-center">
            <img src={yellowCircle} alt="Yellow Circle" className="w-full h-full" />
        </div>
        {/* Orange Circle*/}
        <div className="absolute top-[32%] left-[14%] w-[6%] flex items-center justify-center rotate-180">
            <img src={orangeCircle} className="w-full h-full" />
        </div>
        {/* Gray Star */}
        <div>
            <img src={grayStar}
             className="absolute top-[38%] left-[36%] w-4 z-10"/>
        </div>
        {/* Green Star */}
        <div>
            <img src={softGreenStar}
             className="absolute top-[32%] left-36 w-6 z-10"/>
        </div>
        {/* Orange Star */}
        <div>
            <img src={bigSoftOrangeStar}
             className="absolute top-[31.5%] right-[45%] w-8 z-10"/>
        </div>
        {/* Orange Star */}
        <div>
            <img src={bigSoftOrangeStar}
             className="absolute top-[18%] left-48 w-4 z-10"/>
        </div>
        {/* Orange Star */}
        <div>
            <img src={bigSoftOrangeStar}
             className="absolute top-[39%] right-[12%] w-4 z-20"/>
        </div>
        {/* Gray Star */}
        <div>
            <img src={grayStar}
             className="absolute top-[39.5%] right-[17%] w-4 z-20"/>
        </div>

        {/* Team */}
        {/* sky blue circle */}
        <div>
            <img src={skybluecircle}
             className="absolute top-[49%] left-[7%] w-40 z-10"/>
        </div>
        {/* red Circle with yellow Dot */}
        <div className="absolute bottom-[31%] right-[10.4%] w-[10%] flex items-center justify-center">
            <img src={redCircle} alt="red Circle" className="w-full h-full" />
            {/* yellow Dot */}
            <div className="absolute w-4 h-4 bg-yellow-500 rounded-full border-2 border-white shadow-md"></div>
        </div>
        {/* blue Circle with green Dot */}
        <div className="absolute bottom-[14.8%] right-[28.7%] w-[5%] flex items-center justify-center">
            <img src={darkBlueCircle} className="w-full h-full -rotate-90" />
            {/* green Dot */}
            <div className="absolute w-4 h-4 right-6 bg-green-500 rounded-full border-2 border-white shadow-md"></div>
        </div>
        {/* red Circle */}
        <div className="absolute bottom-[15%] left-[-2%] w-[10%]">
            <img src={redCircle} alt="red Circle" className="w-full h-full" />
        </div>
        {/* sky blue circle */}
        <div>
            <img src={skybluecircle}
             className="absolute bottom-[7%] left-[19%] w-28 z-10"/>
        </div>
        {/* yellow circle */}
        <div>
            <img src={yellowCircle}
             className="absolute bottom-12 right-[19%] w-28 z-10"/>
        </div>
        {/* Strip red */}
        <div className="absolute bottom-[43%] right-[15%] w-44 h-[100px] z-10">
            <img src={stripRed}/>
        </div>
        {/* Strip dev */}
        <div className="absolute bottom-[31%] left-[24%] w-56 h-52 z-10">
            <img src={stripdevlead}/>
        </div>
        {/* Strip blue */}
        <div className="z-10">
            <img src={stripblue} className="absolute bottom-[4%] right-[31%] w-[7%] "/>
        </div>
        {/* Gray Star */}
        <div>
            <img src={grayStar}
             className="absolute bottom-[40%] left-[33%] w-4 z-10"/>
        </div>
        {/* Gray Star */}
        <div>
            <img src={grayStar}
             className="absolute bottom-[25%] right-[36%] w-4 z-10"/>
        </div>
        {/* Orange Star */}
        <div>
            <img src={bigSoftOrangeStar}
             className="absolute bottom-[30%] left-[13%] w-8 z-10"/>
        </div>
        {/* blue star */}
        <div>
            <img src={softBlueStar}
             className="absolute bottom-[38%] right-[30%] w-6 z-10"/>
        </div>
        {/* tosca star */}
        <div>
            <img src={softToscaStar}
             className="absolute bottom-[16%] left-[35%] w-6 z-10"/>
        </div>
        {/* green star */}
        <div>
            <img src={softGreenStar}
             className="absolute bottom-[5%] left-[30%] w-8 z-10"/>
        </div>
        {/* greenish star */}
        <div>
            <img src={greenishStar}
             className="absolute bottom-[10%] right-[20%] w-8 z-10"/>
        </div>
        {/* blue star */}
        <div>
            <img src={softBlueStar}
             className="absolute bottom-[2%] right-[15%] w-6 z-10"/>
        </div>
    </>
  );
};

export default RFGraphics;

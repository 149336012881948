import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from React Router

const Hero = () => {
  const navigate = useNavigate(); // Initialize the navigate function

  const handleLearnMore = () => {
    navigate('/about'); // Navigate to the About page when clicked
  };

  return (
    <main className="relative flex flex-col items-center justify-center h-[750px] space-y-6 text-center z-20 px-4 pt-0 top-0">
      <h1 className="text-5xl lg:text-8xl sm:text-6xl font-extrabold font-crushed top-0">
        TECHOFEST 2024
      </h1>
      <p className="w-2/3 text-gray-600 text-sm sm:w-3/5 lg:text-xl font-montserrat">
        Where Innovation Meets Opportunity – Empowering Lives, Elevating Business with AI!
      </p>
      <button
        onClick={handleLearnMore} // Call the function when clicked
        className="bg-black text-white px-8 py-3 rounded-full text-lg hover:bg-gray-800 transition"
      >
        Learn More
      </button>
    </main>
  );
};

export default Hero;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ProjectTitle from './project-title';
import ProjectDetails from './project-details';
import ProjectSlider from './project-slider';
import DecorativeGraphics from './decorative-graphics';
import SliderNavigationButton from './slider-navigation-button';
import pic1 from '../../../assets/images/landing-page/our-project/pic1.png';
import pic2 from '../../../assets/images/landing-page/our-project/pic2.png';
import pic3 from '../../../assets/images/landing-page/our-project/pic3.png';
import pic4 from '../../../assets/images/landing-page/our-project/pic4.png';

// Define projects and their content
const projects = [
  {
    id: 'techoverse',
    title: 'Techoverse',
    description: 'Explore our portfolio and discover projects that reflect our commitment to innovation and excellence.',
    images: [pic1, pic2, pic3, pic4],
  },
  {
    id: 'spark',
    title: 'Spark',
    description: 'Ignite your ideas with Spark, where creativity meets technology.',
    images: [pic1, pic2, pic3, pic4],
  },
  {
    id: 'crypnity',
    title: 'Crypnity',
    description: 'Unifying security and innovation with Crypnity’s cutting-edge solutions.',
    images: [pic1, pic2, pic3, pic4],
  },
  {
    id: 'reportformatter',
    title: 'ReportFormatter',
    description: 'Seamlessly format your reports with our state-of-the-art tools.',
    images: [pic1, pic2, pic3, pic4],
  },
];

const OurProject = () => {
  const [currentProjectIndex, setCurrentProjectIndex] = useState(0); 
  const [activeImageIndex, setActiveImageIndex] = useState(0); 
  const navigate = useNavigate(); // Initialize useNavigate

  // Navigate between projects
  const nextProject = () =>
    setCurrentProjectIndex((prev) => (prev + 1) % projects.length);
  const prevProject = () =>
    setCurrentProjectIndex((prev) => (prev - 1 + projects.length) % projects.length);

  // Get the current project data
  const currentProject = projects[currentProjectIndex];

  const handleLearnMore = () => {
    navigate(`/project/${currentProject.id}`); // Navigate with project ID as URL param
  };

  // Helper function to get the next set of thumbnails for the active project
  const getNextThumbnails = () => {
    const startIndex = (activeImageIndex + 1) % currentProject.images.length;
    return [
      currentProject.images[startIndex],
      currentProject.images[(startIndex + 1) % currentProject.images.length],
      currentProject.images[(startIndex + 2) % currentProject.images.length],
    ];
  };

  return (
    <div className="relative lg:h-[110vh] h-[100vh] bg-[#faf8ef] overflow-hidden px-20 py-12">
      <ProjectTitle title={currentProject.title} />

      <div className="relative flex justify-between items-center space-x-8">
        {/* Left Button - Switch Projects */}
        <div className="absolute left-[-50px] sm:left-0 transform -translate-x-1/2">
          <SliderNavigationButton direction="left" onClick={prevProject} />
        </div>

        {/* Left Section - Project Details and Thumbnails */}
        <div className="sm:max-w-sm space-y-4 md:space-y-8">
          <ProjectDetails
            title={currentProject.title}
            description={currentProject.description}
          />
          <div className="flex space-x-4 lg:ml-3 xl:ml-11">
            {getNextThumbnails().map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Thumbnail ${index + 1}`}
                className={`object-cover rounded-lg cursor-pointer ${
                  index === 0
                    ? 'w-[39px] h-[33px] sm:w-[50px] sm:h-[50px] md:w-[70px] md:h-[70px] lg:w-[100px] xl:w-[228px] lg:h-[100px]' 
                    : index === 1
                    ? 'w-[39px] h-[33px] sm:w-[50px] sm:h-[50px] md:w-[70px] md:h-[70px] lg:w-[100px] xl:w-[95px] lg:h-[100px]' 
                    : 'w-[39px] h-[33px] sm:w-[50px] sm:h-[50px] md:w-[70px] md:h-[70px] lg:w-[100px] xl:w-[144px] lg:h-[100px]' 
                }`}
                onClick={() =>
                  setActiveImageIndex((activeImageIndex + index + 1) % currentProject.images.length)
                }
              />
            ))}
          </div>
          {/* Learn More Button */}
          <div className="lg:ml-3 xl:ml-11">
            <button className="bg-black text-white text-[10px] md:text-[18px] px-4 py-1 md:px-6 md:py-2 rounded-full hover:bg-gray-800 transition"
            onClick={handleLearnMore}>
              Learn More
            </button>
          </div>
        </div>

        {/* Slider Section */}
        <ProjectSlider
          images={currentProject.images}
          activeIndex={activeImageIndex}
          setActiveIndex={setActiveImageIndex}
        />

        {/* Right Button - Switch Projects */}
        <div className="absolute right-[-50px] sm:right-0 transform translate-x-1/2">
          <SliderNavigationButton direction="right" onClick={nextProject} />
        </div>
      </div>

      <DecorativeGraphics />
    </div>
  );
};

export default OurProject;

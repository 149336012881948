import React from 'react';
import blueCircles from '../../../assets/images/landing-page/hero-section/blue-circles-techofest.png';
import yellowCircle from '../../../assets/images/landing-page/hero-section/yellow-circle.png';
import stripRight from '../../../assets/images/landing-page/hero-section/strip-lines-left.png';
import stripLeft from '../../../assets/images/landing-page/hero-section/strip-lines-right.png';
import greenHalfCircle from '../../../assets/images/landing-page/hero-section/half-circle-green.png';
import blueHalfCircle from '../../../assets/images/landing-page/hero-section/half-circle-blue.png';
import redCircle from '../../../assets/images/landing-page/hero-section/red-circle.png';
import softBlueStar from '../../../assets/images/landing-page/hero-section/soft-blue-star.png';
import softOrangeStar from '../../../assets/images/landing-page/hero-section/soft-orange-star.png';
import softGreenStar from '../../../assets/images/landing-page/hero-section/soft-green-star.png';
import hardGreenStar from '../../../assets/images/landing-page/hero-section/hard-green-star.png';
import softToscaStar from '../../../assets/images/landing-page/hero-section/soft-tosca-star.png';
import bigSoftOrangeStar from '../../../assets/images/landing-page/hero-section/big-soft-orange-star.png';

const Graphics = () => {
  return (
    <div className="absolute inset-0 pointer-events-none">
      {/* Right Strip Line */}
      <img
        src={stripRight}
        alt="Strip Right"
        className="absolute hidden md:block top-[14%] right-[19%] sm:w-32 sm:h-48 md:w-48 md:h-72 lg:w-80 lg:h-96"
      />

      {/* Blue Half Circle */}
      <img
        src={blueHalfCircle}
        alt="Blue Half Circle"
        className="absolute top-[72px] right-[11%] lg:top-0 lg:right-[12%] w-28 sm:w-32 md:w-36 lg:w-48 lg:h-20"
      />

      {/* Yellow Circle with Red Dot */}
      <div className="absolute top-[15%] md:top-[20%] left-[3%] lg:top-[25%] lg:left-[10%] w-28 sm:w-36 sm:h-36 lg:w-48 lg:h-48 flex items-center justify-center">
        <img src={yellowCircle} alt="Yellow Circle" className="w-full h-full" />

        {/* Red Dot */}
        <div className="absolute w-6 h-6 sm:w-8 sm:h-8 bg-red-500 rounded-full border-2 border-white shadow-md"></div>

        {/* Strip Line Image Connecting to Red Dot */}
        <img
          src={stripLeft}
          alt="Strip Left"
          className="absolute hidden sm:block top-[70%] left-[47.5%] -translate-y-2 h-96 w-16 md:w-20 md:h-80 lg:w-28 lg:h-80"
        />
      </div>

      {/* Blue Circles with Logo */}
      <img
        src={blueCircles}
        alt="Blue Circles"
        className="absolute bottom-[16%] md:bottom-[12%] lg:bottom-10 left-[35%] transform -translate-x-1/2 w-44 sm:w-52 sm:h-40 md:w-72 md:h-52 lg:w-80 lg:h-52"
      />

      {/* Red Circle */}
      <img
        src={redCircle}
        alt="Red Circle"
        className="absolute top-[48%] right-[-7%] md:top-[54%] md:right-[13%] transform -translate-x-1/2 w-16 h-16 sm:w-20 sm:h-20 lg:w-24 lg:h-24"
      />

      {/* Green Half Circle */}
      <img
        src={greenHalfCircle}
        alt="Green Half Circle"
        className="absolute top-[35%] right-0 w-16 sm:w-24 sm:h-32 sm:lg:w-36 lg:h-48"
      />

      {/* Decorative Stars */}
      <img
        src={softOrangeStar}
        alt="Soft Orange Star"
        className="absolute bottom-[10%] left-[17%] w-6 h-6 sm:w-8 sm:h-8 lg:w-10 lg:h-10"
      />
      <img
        src={softBlueStar}
        alt="Soft Blue Star"
        className="absolute top-[10%] left-[28%] w-8 h-8 sm:w-10 sm:h-10 lg:w-12 lg:h-12"
      />
      <img
        src={softGreenStar}
        alt="Soft Green Star"
        className="absolute top-[22%] right-[24%] w-7 h-7 sm:w-8 sm:h-8 lg:w-9 lg:h-9"
      />
      <img
        src={hardGreenStar}
        alt="Hard Green Star"
        className="absolute bottom-[42%] left-[12%] w-5 h-5 sm:w-6 sm:h-6 lg:w-8 lg:h-8"
      />
      <img
        src={softToscaStar}
        alt="Soft Tosca Star"
        className="absolute bottom-[15%] right-[40%] w-6 h-6 sm:w-8 sm:h-8"
      />
      <img
        src={bigSoftOrangeStar}
        alt="Big Soft Orange Star"
        className="absolute top-[70%] right-[10%] w-20 h-20 sm:w-24 sm:h-24 lg:w-28 lg:h-28"
      />
    </div>
  );
};

export default Graphics;

import React from 'react';
import SectionTitle from './title-desc';
import Preview from './preview';
import TeamCrypnity from './team-crypnity';
import pic1 from '../../assets/images/gp-details/crypnity1.png';
import pic2 from '../../assets/images/gp-details/crypnity2.png';
import pic3 from '../../assets/images/gp-details/crypnity3.png';
import pic4 from '../../assets/images/gp-details/crypnity4.png';
import CrypnityGraphics from './crypnity-graphics';

const Crypnity = () => {
  const title = 'Crypnity';
  const description = 'Crypnity adalah mata uang digital yang dirancang untuk menyediakan solusi finansial yang aman, transparan, dan terdesentralisasi. Menggabungkan teknologi blockchain terbaru dengan prinsip-prinsip keamanan dan efisiensi, Crypnity bertujuan untuk menawarkan alternatif yang handal dalam transaksi digital.';

  return (
    <div className="relative gp-details px-6 py-1">
      <CrypnityGraphics />
      <SectionTitle
      title = {title}
      description={description} />

      <Preview 
      pic1={pic1}
      pic2={pic2}
      pic3={pic3}
      pic4={pic4}/>

    <TeamCrypnity title={title}/>

    </div>
  );
};

export default Crypnity;

import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ReportFormatterImage from '../../../assets/images/grand-project-story/reportformatter.png';
import TechoverseImage from '../../../assets/images/grand-project-story/techoverse.png';
import TeamSection from '../../projects/project-team';
import DecorativeGraphics from '../../projects/decorative-graphics';

const Projects = [
  {
    id: 'techoverse',
    title: 'Techoverse',
    description: `Techoverse menghadirkan pengalaman virtual yang sepenuhnya interaktif dan imersif, di mana Anda dapat menjelajahi Fakultas Teknik Universitas Sam Ratulangi secara 3D. Inovasi ini dirancang khusus untuk memberi Anda pengalaman tanpa batas ke setiap sudut Fakultas teknik Unsrat.`,
    images: [TechoverseImage, TechoverseImage],
    color: 'bg-blue-500',
    route: '/techoverse-2023', 
  },
  {
    id: 'reportformatter',
    title: 'Report Formatter',
    description: `ReportFormatter hadir sebagai alat bantu yang dirancang khusus untuk mempermudah proses penulisan skripsi Anda. tak perlu lagi khawatir tentang format yang rumit atau aturan penulisan yang membingungkan.`,
    images: [ReportFormatterImage, ReportFormatterImage],
    color: 'bg-green-500',
    route: '/rf-2023',
  },
];

const Project2023 = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const { project } = useParams();
  const navigate = useNavigate(); 

  useEffect(() => {
    window.scrollTo(0, 0);
    const projectIndex = Projects.findIndex(p => p.id === project);
    if (projectIndex !== -1) {
      setCurrentIndex(projectIndex);
    }
  }, [project]);

  const handleDotClick = (index) => {
    setCurrentIndex(index);
  };

  const { title, description, images } = Projects[currentIndex];

  const handleLearnMore = () => {
    navigate(Projects[currentIndex].route);
  };

  return (
    <div
      className="min-h-screen flex flex-col items-center justify-center relative"
      style={{ backgroundColor: '#faf8ef' }}
    >
      <DecorativeGraphics />
      <h1 className="text-8xl font-crushed mt-12 mb-24">Project 2023</h1>

      {/* Left-Aligned Dots Container */}
      <div className="flex justify-start absolute px-8 top-[7.9%] left-[5.5%]">
        <div className="flex items-center space-x-2 border-2 border-b-0 border-r-0 border-black bg-transparent rounded-md px-3 pr-0"
        style={{ backgroundColor: '#EEEBE3' }}>
          {Projects.map((project, index) => (
            <div
              key={index}
              className={`flex items-center justify-center cursor-pointer transition ${
                index === currentIndex
                  ? 'w-auto px-4 py-2 mx-0 border-r-2 border-black rounded-md'
                  : ''
              }`}
              onClick={() => handleDotClick(index)}
            >
              {index === currentIndex ? (
                <span className="text-lg font-medium">{project.title}</span>
              ) : (
                <div className="flex items-center px-4 py-2 mx-0 border-r-2 border-black space-x-2 rounded-md">
                  <div className={`w-6 h-6 rounded-full ${project.color}`}></div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>

      {/* Envelope Wrapper with Project Content */}
      <div className="relative w-[85%] border-2 mt-1 border-black rounded-xl shadow-lg overflow-hidden" style={{ backgroundColor: '#EEEBE3' }}>
        <div className="relative p-8 flex h-[450px]"> {/* Increased Height */}
          <div className="flex-1 relative">
            {images.map((img, idx) => (
              <img
                key={idx}
                src={img}
                alt={`${title} screenshot ${idx + 1}`}
                className="absolute w-[225px] h-[325px] rounded-lg shadow-md"
                style={{
                  top: idx === 0 ? '40%' : '60%',
                  left: idx === 0 ? '40%' : '75%',
                  transform: `translate(-50%, -50%) rotate(${idx === 0 ? '-10deg' : '8deg'})`,
                }}
              />
            ))}
          </div>

          {/* Project Description */}
          <div className="flex-1 flex flex-col items-end justify-center pl-8 mr-36">
            <p className="text-black-200 mb-4 text-start w-[80%]" style={{ fontSize: '23px' }}>{description}</p>
            <button
              className="bg-black text-white px-6 py-3 rounded-full hover:bg-gray-800 transition"
              onClick={handleLearnMore}>
              Learn More
            </button>
          </div>
        </div>
      </div>
      <TeamSection />
    </div>
  );
};

export default Project2023;

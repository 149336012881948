import React from "react";
import orangeCircle from '../../assets/images/about/orangeCircle.png';
import yellowCircle from '../../assets/images/landing-page/hero-section/yellow-circle.png';
import stripLine from '../../assets/images/projects/strip-line.png';
import triangleRight from '../../assets/images/landing-page/futurescape/triangle-right.png';
import redCircle from "../../assets/images/landing-page/hero-section/red-circle.png";
import grayStar from '../../assets/images/about/graystar.png';
import twoGreenCricles from "../../assets/images/landing-page/our-project/two-green-circles.png";
import greenishStar from '../../assets/images/landing-page/our-project/greenish-star.png';
import softGreenStar from '../../assets/images/landing-page/hero-section/soft-green-star.png';
import softToscaStar from '../../assets/images/landing-page/hero-section/soft-tosca-star.png';
import bigSoftOrangeStar from '../../assets/images/landing-page/hero-section/big-soft-orange-star.png';

const DecorativeGraphics = () => {
  return (
    <>
        {/* Red Circle */}
        <img
        src={redCircle}
        alt="Red Circle"
        className="absolute w-52 top-[5%] left-0"
        />
        {/* Yellow Circle with Red Dot */}
        <div className="absolute top-[22%] right-0 w-[10%] flex items-center justify-center">
            <img src={yellowCircle} alt="Yellow Circle" className="w-full h-full" />

            {/* Red Dot */}
            <div className="absolute w-6 h-6 bg-red-500 rounded-full border-2 border-white shadow-md"></div>
        </div>
        {/* Orange Circle */}
        <img
        src={orangeCircle}
        alt="Orange Circle"
        className="absolute w-24 top-[27%] left-[35%] rotate-90"
        />
    {/* Strip line + triangle */}
    <div className="absolute top-[4%] right-[4.8%]">
        <img
        src={stripLine}
        alt="Strip Line"
        className="z-10 w-[380px] h-[480px]"
        />
        <img
        src={triangleRight}
        alt="Triangle Right"
        className="absolute top-[-15px] right-[390px] w-6"
        />
    </div>
    {/* Two Green Circles */}
    <img
        src={twoGreenCricles}
        alt="Two Green Circles"
        className="absolute top-[3%] right-[4%] w-16 h-24"
        />
    {/* Stars */}
        <img
        src={softToscaStar}
        className="absolute w-6 h-6 top-[5%] right-[17%]"
        />
        <img
        src={grayStar}
        className="absolute w-6 h-6 top-[8%] left-[60%]"
        />
        <img
        src={grayStar}
        className="absolute w-6 h-6 top-[1%] left-[8%]"
        />
        <img
        src={greenishStar}
        className="absolute w-8 h-8 top-[20px] right-[33%]"
        />
        <img
        src={bigSoftOrangeStar}
        className="absolute w-6 h-6 top-[5%] left-[25%]"
        />
        <img
        src={bigSoftOrangeStar}
        className="absolute w-6 h-6 top-[25%] left-[3%]"
        />
        <img
        src={softGreenStar}
        className="absolute w-6 h-6 top-[32%] left-[18%]"
        />
        <img
        src={softToscaStar}
        className="absolute w-6 h-6 top-[30%] right-[30%]"
        />
    </>
  );
};

export default DecorativeGraphics;

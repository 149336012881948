import React from 'react';
import greenCircle from '../../../assets/images/landing-page/techofest-members/greencircle.png';
import orangeCircle from '../../../assets/images/landing-page/techofest-members/orangecircle.png';
import blueStar from '../../../assets/images/landing-page/hero-section/soft-blue-star.png';
import orangeStar from '../../../assets/images/landing-page/hero-section/big-soft-orange-star.png';
import blueCircle from '../../../assets/images/landing-page/techofest-members/skybluecircle.png';
import yellowCircle from '../../../assets/images/landing-page/hero-section/yellow-circle.png';
import redCircle from '../../../assets/images/landing-page/hero-section/red-circle.png';

const DecorativeGraphics
 = () => (
  <div className="absolute inset-0 pointer-events-none z-0">
    {/* Green Circle */}
    <img 
    src={greenCircle} 
    alt="Green Circle"
    className='absolute w-20 right-0' 
    />
    
    {/* Orange Circle */}
    <img 
    src={orangeCircle} 
    alt="Orange Circle"
    className='absolute w-16 left-0 top-28' 
    />
    
    {/* Blue Star */}
    <img 
    src={blueStar} 
    alt="Blue Star"
    className='absolute w-8 left-[30%] top-[18%]' 
    />
    
    {/* Orange Star */}
    <img 
    src={orangeStar} 
    alt="Orange Star"
    className='absolute w-12 right-[18%] top-[0]' 
    />
    
    {/* Blue Circle */}
    <img 
    src={blueCircle} 
    alt="Blue Circle"
    className='absolute w-36 left-[25%] bottom-[6%]' 
    />
    
    {/* Yellow Circle */}
    <img 
    src={yellowCircle} 
    alt="Yellow Circle"
    className='absolute w-36 right-[31%] bottom-[33%]' 
    />
    
    {/* Red Circle */}
    <img 
    src={redCircle} 
    alt="Red Circle"
    className='absolute w-36 right-[0] bottom-[8%]' 
    />
  </div>
);

export default DecorativeGraphics
;

import React, { useState } from 'react';
import SpeakerCard from './speaker-card.jsx';
import NavigationButtons from './navigation-buttons.jsx';
import QuoteSlide from './quote-slide.jsx';
import DecorativeGraphicsFuturescape from './decorative-graphics.jsx';
import '../../../assets/styles/input.css';

const Futurescape = () => {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleNext = () => setIsFlipped(true);
  const handlePrevious = () => setIsFlipped(false);

  return (
    <div className="relative min-h-screen flex flex-col items-center justify-center bg-[#faf8ef] my-32 mb-36">
      <DecorativeGraphicsFuturescape />

      <h1 className="text-8xl font-medium tracking-wide mb-8 mt-4 font-crushed z-10">
        Futurescape
      </h1>

      {/* Flip Card Container */}
      <div className={`flip-card ${isFlipped ? 'flipped' : ''} relative z-10`}>
        <div className="flip-card-inner">
          {/* Front Side */}
          <div className="flip-card-front">
            <SpeakerCard />
          </div>

          {/* Back Side with Blurry Quote Slide */}
          <div className="flip-card-back">
            <QuoteSlide />
          </div>
        </div>
      </div>

      {/* Navigation Buttons */}
      <div className="mt-8 z-20">
        <NavigationButtons onNext={handleNext} onPrevious={handlePrevious} />
      </div>
    </div>
  );
};

export default Futurescape;

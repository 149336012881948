// IntroTemplate.jsx
import React from 'react';
import DecorativeGraphics from './decorative-graphics';
import DropdownTitle from './dropdown-title';

const IntroTemplate = ({ content, targetPage }) => {
  return (
    <section className="bg-[#faf8ef] min-h-screen py-16 px-8 relative overflow-hidden">
      <DecorativeGraphics />

      {/* Content Section */}
      <div className="flex justify-center items-center text-center min-h-screen">
        <p className="text-2xl text-center text-gray-800 leading-relaxed font-montserrat w-[60%]">
          {content}
        </p>
      </div>

      {/* Title at the Bottom using DropdownTitle with targetPage prop */}
      <div className="absolute bottom-16 right-8">
        <DropdownTitle initialTitle="Techofest 2024" targetPage={targetPage} />
      </div>
    </section>
  );
};

export default IntroTemplate;

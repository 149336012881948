import React, {useEffect} from 'react';
import TechofestAbout from './techofest-about/techofest-about';
import TechofestIntro from './techofest-intro/techofest-intro';
import Gallery from './gallery/gallery';

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div className="min-h-screen">
      <TechofestAbout />
      <TechofestIntro />
      <Gallery />
    </div>
  );
};

export default About;

import React from 'react';
import blueCircles from '../../../assets/images/landing-page/hero-section/blue-circles-techofest.png';
import yellowCircle from '../../../assets/images/landing-page/hero-section/yellow-circle.png';
import stripLeft from '../../../assets/images/landing-page/hero-section/strip-lines-right.png';
import greenHalfCircle from '../../../assets/images/landing-page/hero-section/half-circle-green.png';
import softBlueStar from '../../../assets/images/landing-page/hero-section/soft-blue-star.png';
import softOrangeStar from '../../../assets/images/landing-page/hero-section/soft-orange-star.png';
import softGreenStar from '../../../assets/images/landing-page/hero-section/soft-green-star.png';
import hardGreenStar from '../../../assets/images/landing-page/hero-section/hard-green-star.png';
import softToscaStar from '../../../assets/images/landing-page/hero-section/soft-tosca-star.png';
import bigSoftOrangeStar from '../../../assets/images/landing-page/hero-section/big-soft-orange-star.png';
import stripIntro from '../../../assets/images/about/strip-intro.png';


const Graphics = () => {
  return (
    <div className="absolute inset-0 pointer-events-none">
      {/* Yellow Circle with Blue Dot */}
      <div className="absolute top-[25%] left-[5%] w-48 h-48 flex items-center justify-center">
        <img src={yellowCircle} alt="Yellow Circle" className="w-full h-full" />

        {/* Blue Dot */}
        <div className="absolute w-6 h-6 sm:w-8 sm:h-8 bg-blue-500 rounded-full border-2 border-white shadow-md"></div>

        {/* Strip Line Image Connecting to Red Dot */}
        <img
          src={stripLeft}
          alt="Strip Left"
          className="absolute block top-[70%] left-[47.5%] -translate-y-2 w-28 h-[350px]"
        />
      </div>

      {/* Blue Circles with Logo */}
      <img
        src={blueCircles}
        alt="Blue Circles"
        className="absolute bottom-[11%] left-[27%] transform -translate-x-1/2 w-64 h-40"
      />

      {/* Green Half Circle */}
      <img
        src={greenHalfCircle}
        alt="Green Half Circle"
        className="absolute top-[24%] right-0 w-16 sm:w-24 sm:h-32 sm:lg:w-36 lg:h-48"
      />

      {/* Strip Intro */}
      <img
        src={stripIntro}
        alt="Strip Intro"
        className="absolute top-[33%] right-0 w-48 h-[470px]"
      />

      {/* Decorative Stars */}
      <img
        src={softOrangeStar}
        alt="Soft Orange Star"
        className="absolute bottom-[10%] left-[17%] w-6 h-6 sm:w-8 sm:h-8 lg:w-10 lg:h-10"
      />
      <img
        src={softBlueStar}
        alt="Soft Blue Star"
        className="absolute top-[10%] left-[28%] w-8 h-8 sm:w-10 sm:h-10 lg:w-12 lg:h-12"
      />
      <img
        src={softGreenStar}
        alt="Soft Green Star"
        className="absolute top-[22%] right-[24%] w-7 h-7 sm:w-8 sm:h-8 lg:w-9 lg:h-9"
      />
      <img
        src={hardGreenStar}
        alt="Hard Green Star"
        className="absolute bottom-[42%] left-[5%] w-5 h-5 sm:w-6 sm:h-6 lg:w-8 lg:h-8"
      />
      <img
        src={softToscaStar}
        alt="Soft Tosca Star"
        className="absolute top-[50%] right-[20%] w-6 h-6 sm:w-8 sm:h-8"
      />
      <img
        src={bigSoftOrangeStar}
        alt="Big Soft Orange Star"
        className="absolute bottom-[10%] right-[30%] w-20 h-20 sm:w-24 sm:h-24 lg:w-28 lg:h-28"
      />
    </div>
  );
};

export default Graphics;

import React from 'react';
import PersonCard from '../techofest-member/person-card'; 
import Satria from '../../../assets/images/projects/satria.png';
import Clarissa from '../../../assets/images/projects/clarissa.png';
import Albert from '../../../assets/images/projects/albert.png';
import stripLeft from '../../../assets/images/landing-page/gp-members/strip-left.png';
import stripRight from '../../../assets/images/landing-page/gp-members/strip-right.png';
import DecorativeGraphics from './decorative-graphics';

const GrandProjectSection = () => {
  return (
    <section className="relative py-16 bg-[#faf8ef] mx-auto">
      <h1 className="text-8xl font-medium tracking-wide mb-10 mt-4 font-crushed z-10 text-center">Grand Project</h1>
      <DecorativeGraphics />

      {/* Grid layout for cards */}
      <div
        className="grid grid-col-1 grid-rows-3 gap-8 justify-center mx-auto"
        style={{ height: '800px' }} >
        {/* Card 1 */}
        <div className="col-start-1 row-start-2">
          <PersonCard
            name="Clarissa Maramis"
            role="Scrum Master"
            image={Clarissa}
          />
        </div>

        {/* Center Card */}
        <div className="col-start-2 row-start-1">
          <PersonCard
            name="Satria Amu"
            role="Project Leader"
            image={Satria}
          />
        <img 
        src={stripLeft}
        alt="Strip Left" 
        className='absolute w-44 h-40 left-[38%]'
        />
        <img 
        src={stripRight}
        alt="Strip Right" 
        className='absolute w-44 h-40 right-[38%]'
        />
        </div>

        {/* Card 3 */}
        <div className="col-start-3 row-start-2">
          <PersonCard
            name="Albert Mangiri"
            role="Tech Lead"
            image={Albert}
          />
        </div>
      </div>
    </section>
  );
};

export default GrandProjectSection;

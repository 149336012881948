// src/components/landing-page/techofest-member/StripAboveCard.jsx
import React from 'react';
import PersonCard from './person-card';
import stripAbove from '../../../assets/images/landing-page/techofest-members/strip-above.png';

const StripAboveCard = ({ name, role, image }) => {
  return (
    <div className="relative flex flex-col items-center">
      {/* Image Strip Line Above */}
      <div className="absolute -top-[40%] right-0 w-44 h-44">
        <img
          src={stripAbove}
          alt="strip-above"
          className="w-full h-full object-contain"
        />
      </div>

      {/* Card Component */}
      <PersonCard name={name} role={role} image={image} />
    </div>
  );
};

export default StripAboveCard;

import React from 'react';
import StripBelowCard from './strip-below-card';
import StripAboveCard from './strip-above-card';
import Cristophel from '../../../assets/images/landing-page/futurescape/dekan.png';
import Yesha from '../../../assets/images/landing-page/futurescape/dekan.png';
import Peter from '../../../assets/images/landing-page/futurescape/dekan.png';
import Arnold from '../../../assets/images/landing-page/futurescape/dekan.png';
import Regina from '../../../assets/images/landing-page/techofest-members/regina.png';
import DecorativeGraphics from './decorative-graphics';

const TechofestSection = () => {
  return (
    <section className="relative py-12 mx-auto">
      <DecorativeGraphics />
      <h1 className="text-8xl font-medium tracking-wide mb-8 mt-4 font-crushed z-10 text-center">Techofest</h1>

      {/* Horizontal Scrollable Grid Container */}
      <div className="overflow-x-auto snap-x snap-mandatory w-[93%] mx-auto hide-scrollbar" 
           style={{ scrollBehavior: 'smooth' }}>
        <div
          className="grid grid-rows-1 gap-5" // Single-row grid for horizontal scrolling
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(7, minmax(25%, 1fr))', 
            height: '800px',
          }}
        >
          {/* Cards */}
          <div className="snap-center col-start-1 row-start-1">
            <StripBelowCard
              name="Cristophel Soge"
              role="Pengarah"
              image={Cristophel}
            />
          </div>

          <div className="snap-center col-start-2 row-start-2">
            <StripAboveCard
              name="Peter Sompotan"
              role="Ketua"
              image={Peter}
            />
          </div>

          <div className="snap-center col-start-3 row-start-1">
            <StripBelowCard
              name="Yesha Laurie"
              role="Wakil Ketua"
              image={Yesha}
            />
          </div>

          <div className="snap-center col-start-4 row-start-2">
            <StripAboveCard
              name="Arnold Simanjuntak"
              role="Sekretaris"
              image={Arnold}
            />
          </div>

          <div className="snap-center col-start-5 row-start-1">
            <StripBelowCard
              name="Christy V. E. Potabuga"
              role="Wakil Sekretaris"
              image={Yesha}
            />
          </div>

          <div className="snap-center col-start-6 row-start-2">
            <StripAboveCard
              name="Regina Agussalim"
              role="Pengarah"
              image={Regina}
            />
          </div>

          <div className="snap-center col-start-7 row-start-1">
            <div className="bg-gray-100 rounded-lg shadow-md py-5 px-0 flex flex-col items-center space-y-3 w-[300px] h-[450px]">
              <img src={Yesha} alt="Claire Dunphy" className="w-full h-[322px] object-cover mx-0" />
              <h3 className="text-xl font-semibold font-montserrat">Claire Dunphy</h3>
              <p className="text-base text-gray-500 font-montserrat">Pengurus</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TechofestSection;

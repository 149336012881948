import React from 'react';
import TeamMemberCard from './team-member-card';
import Galnoel from '../../assets/images/gp-details/Galnoel.png';
import Ridho from '../../assets/images/gp-details/Ridho.png';
import Regina from '../../assets/images/gp-details/Regina.png';
import Triadi from '../../assets/images/gp-details/Triadi.png';
import Made from '../../assets/images/gp-details/Made.png';
import Michael from '../../assets/images/gp-details/Michael.png';
import Gifriend from '../../assets/images/gp-details/Gifriend.png';
import Freya from '../../assets/images/gp-details/Freya.png';
import Daffa from '../../assets/images/gp-details/Daffa.png';
import Natalio from '../../assets/images/gp-details/Natalio.png';
import Sabdawita from '../../assets/images/gp-details/Sabdawita.png';

// Define team members with images
const teamMembers = [
  { name: 'Galnoel Rindengan', role: 'Dev Lead', image: Galnoel }, // Top row, alone
  { name: 'Ridho Aditya', role: 'Anggota', image: Ridho },
  { name: 'Regina George', role: 'Anggota', image: Regina },
  { name: 'Triadi M', role: 'Anggota', image: Triadi },
  { name: 'Made Indra', role: 'Anggota', image: Made },
  { name: 'Michael Manoppo', role: 'Anggota', image: Michael },
  { name: 'Gifriend Talumingan', role: 'Anggota', image: Gifriend },
  { name: 'Freya Tombokan', role: 'Anggota', image: Freya },
  { name: 'Daffa Nurfiat', role: 'Anggota', image: Daffa },
  { name: 'Cindy Indiarto', role: 'Anggota', image: Daffa }, // Duplicate image as requested
  { name: 'Natalio Tumuahi', role: 'Anggota', image: Natalio }, // Bottom row
  { name: 'Sabdawita Salibana', role: 'Anggota', image: Sabdawita }, // Bottom row
];

const TeamSpark = () => {
  return (
    <div className="flex flex-col items-center gap-10 mt-10">
      <h1 className="text-7xl font-crushed my-12">Spark Team</h1>
      
      {/* Grid Layout for Team Members */}
      <div className="grid grid-cols-3 gap-10">
        
        {/* Top row: Galnoel alone in the center */}
        <div></div> {/* Empty spot for alignment */}
        <TeamMemberCard image={Galnoel} name="Galnoel Rindengan" role="Dev Lead" />
        <div></div> {/* Empty spot for alignment */}

        {/* Second Row: Ridho - Regina - Triadi */}
        <TeamMemberCard image={Ridho} name="Ridho Aditya" role="Anggota" />
        <TeamMemberCard image={Regina} name="Regina George" role="Anggota" />
        <TeamMemberCard image={Triadi} name="Triadi M" role="Anggota" />

        {/* Third Row: Made - Michael - Gifriend */}
        <TeamMemberCard image={Made} name="Made Indra" role="Anggota" />
        <TeamMemberCard image={Michael} name="Michael Manoppo" role="Anggota" />
        <TeamMemberCard image={Gifriend} name="Gifriend Talumingan" role="Anggota" />

        {/* Fourth Row: Freya - Daffa - Cindy */}
        <TeamMemberCard image={Freya} name="Freya Tombokan" role="Anggota" />
        <TeamMemberCard image={Daffa} name="Daffa Nurfiat" role="Anggota" />
        <TeamMemberCard image={Daffa} name="Cindy Indiarto" role="Anggota" />

        {/* Fifth Row: Natalio and Sabdawita centered */}
        <div className="col-span-3 flex justify-center gap-10">
          <TeamMemberCard image={Natalio} name="Natalio Tumuahi" role="Anggota" />
          <TeamMemberCard image={Sabdawita} name="Sabdawita Salibana" role="Anggota" />
        </div>
      </div>
    </div>
  );
};

export default TeamSpark;

import React from 'react';
import SectionTitle from './title-desc';
import Preview from './preview';
import TeamSpark from './team-spark';
import pic1 from '../../assets/images/gp-details/spark1.png';
import pic2 from '../../assets/images/gp-details/spark2.png';
import pic3 from '../../assets/images/gp-details/spark3.png';
import pic4 from '../../assets/images/gp-details/spark4.png';
import SparkGraphics from './spark-graphics';

const Spark = () => {
  const title = 'Spark';
  const description = 'Dengan kemampuan memahami dan merespons pertanyaan pengguna secara akurat, SPARK menyediakan jawaban cepat dan relevan untuk berbagai kebutuhan. Platform ini juga mendukung komunikasi berbasis suara melalui website interaktif, memberikan kenyamanan dan fleksibilitas dalam berinteraksi. Selain itu, SPARK dirancang untuk meningkatkan produktivitas dengan menyediakan alat bantu cerdas yang membantu menyelesaikan tugas sehari-hari secara efisien.';

  return (
    <div className="relative gp-details px-6 py-1">
      <SparkGraphics />
      <SectionTitle
      title = {title}
      description={description} />

      <Preview 
      pic1={pic1}
      pic2={pic2}
      pic3={pic3}
      pic4={pic4}/>

    <TeamSpark title={title}/>

    </div>
  );
};

export default Spark;

import React from "react";
import { useNavigate } from "react-router-dom";
import { FaGithub, FaLinkedin, FaInstagram } from "react-icons/fa";
import unityLogo from '../../../assets/images/landing-page/hero-section/unity-logo.png';
import techofestLogo from '../../../assets/images/landing-page/hero-section/techofest-logo.png';

const Footer = () => {
  const navigate = useNavigate(); // Initialize navigate

  // Navigation function for each product
  const navigateToProject = (project) => {
    navigate(`/project/${project}`);
  };

  return (
    <footer className="bg-[#FFF8F2] py-10 px-6 text-center">
      {/* Logos Section */}
      <div className="flex justify-center space-x-8 mb-8">
        <img
          src={unityLogo}
          alt="Unity Logo"
          className="h-20 w-20 object-contain"
        />
        <img
          src={techofestLogo}
          alt="Techofest Logo"
          className="h-20 w-20 object-contain"
        />
      </div>

      {/* List of Products with small spaces around the separator */}
      <div className="text-base text-gray-800 mb-8 tracking-wider font-montserrat">
        <span
          className="cursor-pointer hover:underline"
          onClick={() => navigateToProject('techoverse')}
        >
          Techoverse
        </span>
        &nbsp;|&nbsp; {/* Adds small space on either side of the separator */}
        <span
          className="cursor-pointer hover:underline"
          onClick={() => navigateToProject('crypnity')}
        >
          Crypnity
        </span>
        &nbsp;|&nbsp; {/* Adds small space on either side of the separator */}
        <span
          className="cursor-pointer hover:underline"
          onClick={() => navigateToProject('reportformatter')}
        >
          Report Formatter
        </span>
        &nbsp;|&nbsp; {/* Adds small space on either side of the separator */}
        <span
          className="cursor-pointer hover:underline"
          onClick={() => navigateToProject('spark')}
        >
          Spark
        </span>
      </div>

      {/* Social Media Icons */}
      <div className="flex justify-center space-x-6 mb-8">
        <a href="https://github.com" target="_blank" rel="noopener noreferrer">
          <FaGithub className="text-3xl text-black hover:scale-110 transition" />
        </a>
        <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
          <FaLinkedin className="text-3xl text-black hover:scale-110 transition" />
        </a>
        <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
          <FaInstagram className="text-3xl text-black hover:scale-110 transition" />
        </a>
      </div>

      {/* Copyright Text */}
      <p className="text-sm text-gray-600 font-montserrat">
        © Copyright UNSRAT IT Community All rights reserved.
      </p>
    </footer>
  );
};

export default Footer;

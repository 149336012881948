import React from 'react';
import twoGreenCircles from "../../../assets/images/landing-page/our-project/two-green-circles.png";
import blueCircle from "../../../assets/images/landing-page/our-project/blue-circle.png";
import redCircle from "../../../assets/images/landing-page/hero-section/red-circle.png";
import orangeCircle from "../../../assets/images/about/orangeCircle.png";
import bigSoftOrangeStar from '../../../assets/images/landing-page/hero-section/big-soft-orange-star.png';
import softToscaStar from '../../../assets/images/landing-page/hero-section/soft-tosca-star.png';
import greenishStar from '../../../assets/images/landing-page/our-project/greenish-star.png';
import grayStar from '../../../assets/images/about/graystar.png';

const DecorativeGraphics = () => {
  return (
    <>
        {/* Two Green Circles */}
        <img
        src={twoGreenCircles}
        alt="Two Green Circles"
        className="absolute w-20 h-24 top-[12%] left-[15%]"
        />

        {/* Blue Circle */}
        <img
        src={blueCircle}
        alt="Blue Circle"
        className="absolute w-52 h-60 top-[12%] right-0 z-0"
        />

        {/* Red Circle */}
        <img
        src={redCircle}
        alt="Red Circle"
        className="absolute w-32 h-32 bottom-0 right-[45%] z-0"
        />

        {/* Orange Circle */}
        <img
        src={orangeCircle}
        alt="Orange Circle"
        className="absolute w-20 h-24 bottom-[25%] left-0 z-0"
        />

        {/* Stars */}
        <img
        src={softToscaStar}
        className="absolute w-6 h-6 top-[30%] left-[45%]"
        />
        <img
        src={softToscaStar}
        className="absolute w-6 h-6 bottom-[9%] left-[25%]"
        />
        <img
        src={grayStar}
        className="absolute w-6 h-6 top-[20%] left-[5%]"
        />
        <img
        src={grayStar}
        className="absolute w-6 h-6 bottom-0 right-[25%]"
        />
        <img
        src={greenishStar}
        className="absolute w-6 h-6 top-[48%] left-[7%]"
        />
        <img
        src={greenishStar}
        className="absolute w-6 h-6 bottom-[30%] right-[6%]"
        />
        <img
        src={bigSoftOrangeStar}
        className="absolute w-8 h-8 bottom-[38%] left-[47%]"
        />
    </>
  );
};

export default DecorativeGraphics;

// DropdownTitle.jsx
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const DropdownTitle = ({ initialTitle = "Techofest 2024", targetPage = {} }) => {
  const navigate = useNavigate();
  const location = useLocation();

  // Determine initial title based on the current path
  const [title, setTitle] = useState(
    location.pathname === (targetPage.techofest2023 || "/about-2023") ? "Techofest 2023" : "Techofest 2024"
  );
  const [isOpen, setIsOpen] = useState(false);

  // Toggle dropdown visibility
  const toggleDropdown = () => setIsOpen(!isOpen);

  // Handle title change and navigation
  const changeTitle = (newTitle) => {
    setTitle(newTitle);
    setIsOpen(false);

    // Navigate based on the selected title and targetPage prop
    if (newTitle === "Techofest 2023") {
      navigate(targetPage.techofest2023 || "/about-2023");
    } else {
      navigate(targetPage.techofest2024 || "/about");
    }
  };

  // Update the title whenever the location changes
  useEffect(() => {
    setTitle(
      location.pathname === (targetPage.techofest2023 || "/about-2023") ? "Techofest 2023" : "Techofest 2024"
    );
  }, [location, targetPage]);

  // Display the opposite title as a dropdown option
  const otherTitle = title === "Techofest 2024" ? "Techofest 2023" : "Techofest 2024";

  return (
    <div className="relative cursor-pointer text-5xl tracking-wider font-crushed text-right">
      <div onClick={toggleDropdown} className="inline-block">
        {title}
      </div>
      {isOpen && (
        <div className="absolute right-0 mt-2 bg-white shadow-lg rounded-md text-4xl font-crushed">
          <div
            className="px-4 py-2 hover:bg-gray-200"
            onClick={() => changeTitle(otherTitle)}
          >
            {otherTitle}
          </div>
        </div>
      )}
    </div>
  );
};

export default DropdownTitle;

// pages/Techofest.jsx
import React from 'react';
import AboutTemplate from './about-template';
import technolimit from '../../../assets/images/landing-page/techofest-years/technolimit.png'; 

const TechofestAbout = () => {
  const title = 'WHAT IS TECHOFEST?';
  const text = `Selamat datang di Techofest 2024, acara digital paling dinanti yang diadakan oleh 
  Unsrat IT Community (UNITY)! Di tahun ini, kami menghadirkan platform yang tidak hanya berfokus 
  pada inovasi teknologi, tetapi juga pada penerapan nilai bisnis yang kuat. Techofest 2024 menjadi 
  ajang di mana teknologi canggih bertemu dengan peluang bisnis yang menjanjikan.`;

  return (
    <AboutTemplate title={title} text={text} image={technolimit} />
  );
};

export default TechofestAbout;

import React from 'react';
import backgroundImage from '../../../assets/images/landing-page/futurescape/background.png';

const QuoteSlide = () => {
  return (
    <div
      className="relative shadow-md flex items-center justify-center ml-auto mr-auto"
      style={{
        width: '85%', 
        height: '535px',
        borderRadius: '50px', 
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      {/* Black Overlay with Blur */}
      <div
        className="absolute inset-0 backdrop-blur-sm"
        style={{
          backgroundColor: '#00000099', // Semi-transparent black overlay
          borderRadius: '50px', // Fully rounded overlay
        }}
      ></div>

      {/* Quote Text */}
      <p className="absolute text-2xl text-center text-white font-light px-12 leading-relaxed z-10">
        Techofest 2024 bertujuan untuk memajukan inovasi digital dalam bisnis
        dan kehidupan sehari-hari dengan menonjolkan penerapan kecerdasan
        buatan (AI). Fokus kami adalah bagaimana AI dapat meningkatkan efisiensi,
        mempermudah proses, dan membuka peluang baru. Dalam acara ini, kami
        akan memamerkan proyek-proyek unggulan yang menunjukkan solusi praktis
        dari teknologi canggih, - Dekan Teknik
      </p>
    </div>
  );
};

export default QuoteSlide;

import React from 'react';

const SectionTitle = ({ title, description }) => {
  return (
    <div className="max-w-6xl mx-auto text-center mb-12 mt-0">
      <p className="flex justify-end text-xl font-montserrat mb-10">{title}</p>
      <h1 className="text-8xl font-crushed mb-12">
        What is {title}
      </h1>
      <p className='flex justify-self-center text-xl font-normal font-black-800 leading-relaxed font-montserrat w-[80%]'>
        {description}
      </p>
    </div>
  );
};

export default SectionTitle;

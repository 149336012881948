import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa'; 
import Crypnity from '../../assets/images/grand-project-story/crypnity.png';
import ReportFormatter from '../../assets/images/grand-project-story/reportformatter.png';
import Spark from '../../assets/images/grand-project-story/spark.png';
import Techoverse from '../../assets/images/grand-project-story/techoverse.png';
import starCard from '../../assets/images/landing-page/techofest-members/star-card.png';
import sunCard from '../../assets/images/landing-page/techofest-members/suncard.png';
import DecorativeGraphics from './decorative-graphics'; 

const Gallery = () => {
  const images = [
    [Crypnity, Spark], 
    [Techoverse, ReportFormatter], 
  ]; 

  const [currentIndex, setCurrentIndex] = useState(0); 
  const navigate = useNavigate(); // Initialize useNavigate

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleNavigation = (img) => {
    let projectParam = '';
    if (img === Crypnity) projectParam = 'crypnity';
    else if (img === Spark) projectParam = 'spark';
    else if (img === Techoverse) projectParam = 'techoverse';
    else if (img === ReportFormatter) projectParam = 'reportformatter';

    if (projectParam) {
      navigate(`/project/${projectParam}`);
    }
  };

  return (
    <div className="relative flex flex-col items-center space-y-6 py-10 mb-24">
      <DecorativeGraphics />
      <h2 className="text-8xl font-crushed mb-6">Project Preview</h2>

      {/* Image Container */}
      <div className="relative w-[85%] overflow-hidden bg-gray-100 rounded-[50px] p-8">
        <p className='flex justify-end text-4xl font-crushed mr-20'>2024</p>
        {/* Decor Elements inside the box */}
        <img
          src={starCard}
          alt="Star Card"
          className="absolute w-8 top-8 left-8 z-10"
        />
        <img
          src={starCard}
          alt="Star Card"
          className="absolute w-8 bottom-12 left-80 z-10"
        />
        <img
          src={sunCard}
          alt="Sun Card"
          className="absolute w-8 bottom-8 right-8 z-10"
        />
        <img
          src={sunCard}
          alt="Sun Card"
          className="absolute w-8 top-12 right-64 z-10"
        />

        <div
          className="flex transition-transform duration-500"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {images.map((pair, index) => (
            <div key={index} className="flex-shrink-0 w-full grid grid-cols-2 gap-12 px-12 py-4">
              {pair.map((img, idx) => (
                <div
                  key={idx}
                  className={`flex flex-col ${
                    idx === 1 ? 'items-end' : 'items-start'
                  }`}
                >
                  <img
                    src={img}
                    alt={`Gallery ${index + 1}-${idx + 1}`}
                    className="w-full h-auto object-cover rounded-lg mb-4"
                  />
                  <button
                    className="bg-black text-white text-lg px-8 w-44 h-[50px] rounded-full hover:bg-gray-800 transition"
                    onClick={() => handleNavigation(img)}
                  >
                    Learn More
                  </button>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>

      {/* Navigation Buttons */}
      <div className="flex space-x-4">
        <button
          onClick={handlePrevious}
          className="p-3 bg-black text-white rounded-full hover:scale-110 transition"
        >
          <FaArrowLeft />
        </button>
        <button
          onClick={handleNext}
          className="p-3 bg-black text-white rounded-full hover:scale-110 transition"
        >
          <FaArrowRight />
        </button>
      </div>
    </div>
  );
};

export default Gallery;

import React from 'react';
import SectionTitle from '../../gp-details/title-desc';
import Preview from '../../gp-details/preview';
import TeamTechoverse from '../../gp-details/team-techoverse';
import pic1 from '../../../assets/images/gp-details/techoverse1.png';
import pic2 from '../../../assets/images/gp-details/techoverse2.png';
import pic3 from '../../../assets/images/gp-details/techoverse3.png';
import pic4 from '../../../assets/images/gp-details/techoverse4.png';
import Graphics from '../../gp-details/techoverse-graphics';

const Techoverse2023 = () => {
  const title = 'Techoverse';
  const description = 'Techoverse menawarkan pengalaman eksplorasi virtual yang interaktif dan bebas di Fakultas Teknik UNSRAT, dengan akses ke laboratorium canggih, ruang kelas, dan fasilitas kampus yang memukau. Anda dapat berkolaborasi dalam mode multiplayer, berinteraksi dengan teman. Tanpa batasan waktu, Anda memiliki kebebasan untuk menjelajahi seluruh area kampus dalam suasana yang dinamis, semuanya dari dalam Techoverse.';

  return (
    <div className="relative gp-details px-6 py-1">
      <Graphics />
      <SectionTitle
      title = {title}
      description={description} />

      <Preview 
      pic1={pic1}
      pic2={pic2}
      pic3={pic3}
      pic4={pic4}/>

      <TeamTechoverse title={title}/>

    </div>
  );
};

export default Techoverse2023;

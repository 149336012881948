import React from 'react';

const TeamMemberCard = ({ image, name, role }) => {
  return (
    <div className="flex flex-col items-center z-20">
    <div className="flex items-end justify-center bg-white rounded-lg shadow-md w-[350px] h-[350px] mb-4">
      <img
        src={image}
        className="w-fit h-80 rounded-lg object-cover"
      />
    </div>
    <div className="flex flex-col items-center">
      <h2 className="text-2xl font-semibold">{name}</h2>
      <p className="text-gray-600 text-xl">{role}</p>
    </div>
  </div>
  );
};

export default TeamMemberCard;

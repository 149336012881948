import React from "react";
import yellowCircle from '../../../assets/images/landing-page/hero-section/yellow-circle.png';
import stripLine from '../../../assets/images/landing-page/techofest-years/strip-line.png';
import triangleRight from '../../../assets/images/landing-page/futurescape/triangle-right.png';
import redCircle from "../../../assets/images/landing-page/hero-section/red-circle.png";
import blueStripCircle from "../../../assets/images/landing-page/techofest-years/blueStripCircle.png";
import twoGreenCricles from "../../../assets/images/landing-page/our-project/two-green-circles.png";
import greenishStar from '../../../assets/images/landing-page/our-project/greenish-star.png';
import softBlueStar from '../../../assets/images/landing-page/hero-section/soft-blue-star.png';
import softGreenStar from '../../../assets/images/landing-page/hero-section/soft-green-star.png';
import softToscaStar from '../../../assets/images/landing-page/hero-section/soft-tosca-star.png';
import bigSoftOrangeStar from '../../../assets/images/landing-page/hero-section/big-soft-orange-star.png';

const DecorativeGraphics = () => {
  return (
    <>
    {/* Yellow Circle with Green Dot */}
    <div className="absolute top-[-10%] left-[-3%] w-60 flex items-center justify-center">
        <img src={yellowCircle} alt="Yellow Circle" className="w-full h-full" />

        {/* Green Dot */}
        <div className="absolute w-6 h-6 bg-green-500 rounded-full border-2 border-white shadow-md"></div>
    </div>

    {/* Strip line + triangle */}
    <div className="absolute top-[14%] left-[5.5%]">
        <img
        src={stripLine}
        alt="Strip Line"
        className="z-10 h-[250px] w-[500px]"
        />
        <img
        src={triangleRight}
        alt="Triangle Right"
        className="absolute bottom-[-12px] left-[520px] w-6 rotate-180"
        />
    </div>

    {/* Red Circle */}
    <img
        src={redCircle}
        alt="Red Circle"
        className="absolute w-32 bottom-[33%] right-[44%]"
        />

    {/* Blue Strip Circle */}
    <img
        src={blueStripCircle}
        alt="Blue Strip Circle"
        className="absolute w-28 top-[-10%] right-[35%]"
        />

    {/* Two Green Circles */}
    <img
        src={twoGreenCricles}
        alt="Two Green Circles"
        className="absolute w-28 h-32 rotate-180 top-[25%] right-[8%]"
        />

    {/* Stars */}
    <img
        src={softBlueStar}
        className="absolute w-6 h-6 top-[-8%] left-[35%]"
        />
    <img
        src={greenishStar}
        className="absolute w-8 h-8 top-[40%] left-[45%]"
        />
    <img
        src={softGreenStar}
        className="absolute w-4 h-4 bottom-[38%] left-[30%]"
        />
    <img
        src={softToscaStar}
        className="absolute w-6 h-6 bottom-[50%] left-[2%]"
        />
    <img
        src={softToscaStar}
        className="absolute w-6 h-6 bottom-[20%] right-[25%]"
        />
    <img
        src={bigSoftOrangeStar}
        className="absolute w-8 h-8 top-[-15%] right-[17%]"
        />
    <img
        src={bigSoftOrangeStar}
        className="absolute w-6 h-6 top-[-25%] left-[-5]"
        />
    </>
  );
};

export default DecorativeGraphics;

import React, { useState } from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa'; 
import pic1 from '../../../assets/images/about/gallerypic1.png';
import pic2 from '../../../assets/images/about/gallerypic2.png';
import starCard from '../../../assets/images/landing-page/techofest-members/star-card.png';
import sunCard from '../../../assets/images/landing-page/techofest-members/suncard.png';
import DecorativeGraphics from './decorative-graphics'; 

const Gallery = () => {
  const images = [
    [pic1, pic2], 
    [pic2, pic2], 
    [pic1, pic1], 
  ]; 

  const [currentIndex, setCurrentIndex] = useState(0); // State to track active slide

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="relative flex flex-col items-center space-y-6 py-10 mb-24">
      <DecorativeGraphics />
      <h2 className="text-8xl font-crushed mb-6">Gallery</h2>

      {/* Image Container */}
      <div className="relative w-[85%] overflow-hidden bg-gray-100 rounded-[50px] p-8">
        {/* Decor Elements inside the box */}
        <img
          src={starCard}
          alt="Star Card"
          className="absolute w-8 top-8 left-8 z-10"
        />
        <img
          src={starCard}
          alt="Star Card"
          className="absolute w-8 bottom-16 left-72 z-10"
        />
        <img
          src={sunCard}
          alt="Sun Card"
          className="absolute w-8 bottom-8 right-8 z-10"
        />
        <img
          src={sunCard}
          alt="Sun Card"
          className="absolute w-8 top-16 right-64 z-10"
        />

        <div
          className="flex transition-transform duration-500"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {images.map((pair, index) => (
            <div
              key={index}
              className="flex-shrink-0 w-full relative h-[500px]"
            >
              {/* First Image - Positioned towards the top */}
              <img
                src={pair[0]}
                alt={`Gallery ${index + 1}-1`}
                className="absolute top-6 left-[10%] transform -translate-x-[10%] w-[40%] h-auto object-cover rounded-lg"
              />

              {/* Second Image - Positioned towards the bottom */}
              <img
                src={pair[1]}
                alt={`Gallery ${index + 1}-2`}
                className="absolute bottom-6 right-[4%] transform -translate-x-[4%] w-[40%] h-auto object-cover rounded-lg"
              />
            </div>
          ))}
        </div>
      </div>

      {/* Navigation Buttons */}
      <div className="flex space-x-4">
        <button
          onClick={handlePrevious}
          className="p-3 bg-black text-white rounded-full hover:scale-110 transition"
        >
          <FaArrowLeft />
        </button>
        <button
          onClick={handleNext}
          className="p-3 bg-black text-white rounded-full hover:scale-110 transition"
        >
          <FaArrowRight />
        </button>
      </div>
    </div>
  );
};

export default Gallery;

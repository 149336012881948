import React from 'react';
import { useNavigate } from 'react-router-dom'; 
import Techoverse from '../../../assets/images/grand-project-story/techoverse.png';
import ReportFormatter from '../../../assets/images/grand-project-story/reportformatter.png';
import starCard from '../../../assets/images/landing-page/techofest-members/star-card.png';
import sunCard from '../../../assets/images/landing-page/techofest-members/suncard.png';
import DecorativeGraphics from './graphics'; 

const Gallery2023 = () => {
  const images = [
    [Techoverse, ReportFormatter], 
  ]; 

  const navigate = useNavigate(); 

  const handleNavigation = (img) => {
    if (img === Techoverse) {
      navigate(`/projects-2023/techoverse`);
    } else if (img === ReportFormatter) {
      navigate(`/projects-2023/reportformatter`);
    }
  };

  return (
    <div className="relative flex flex-col items-center space-y-6 py-10 mb-24">
      <DecorativeGraphics />
      <h2 className="text-8xl font-crushed mb-6">Project Preview</h2>

      {/* Image Container */}
      <div className="relative w-[85%] overflow-hidden bg-gray-100 rounded-[50px] p-8">
        <p className='flex justify-end text-4xl font-crushed mr-20'>2023</p>
        {/* Decor Elements inside the box */}
        <img
          src={starCard}
          alt="Star Card"
          className="absolute w-8 top-8 left-8 z-10"
        />
        <img
          src={starCard}
          alt="Star Card"
          className="absolute w-8 bottom-12 left-80 z-10"
        />
        <img
          src={sunCard}
          alt="Sun Card"
          className="absolute w-8 bottom-8 right-8 z-10"
        />
        <img
          src={sunCard}
          alt="Sun Card"
          className="absolute w-8 top-12 right-64 z-10"
        />

        <div className="w-full justify-center grid grid-cols-2 gap-12 px-12 py-4">
          {images[0].map((img, idx) => (
            <div
              key={idx}
              className={`flex flex-col ${idx === 1 ? 'items-end' : 'items-start'}`}
            >
              <img
                src={img}
                alt={`Gallery ${idx + 1}`}
                className="w-full h-auto object-cover rounded-lg mb-4"
              />
              <button
                className="bg-black text-white text-lg px-8 w-44 h-[50px] rounded-full hover:bg-gray-800 transition"
                onClick={() => handleNavigation(img)}
              >
                Learn More
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Gallery2023;

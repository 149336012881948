import React from 'react';
import ReactDOM from 'react-dom/client'; // Use the new React 18 API
import './assets/styles/input.css';  // Import Tailwind CSS
import App from './App';

// Create the root using createRoot
const root = ReactDOM.createRoot(document.getElementById('root'));

// Render the App
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

import React from 'react';
import SectionTitle from './title-desc';
import Preview from './preview';
import TeamRF from './team-rf';
import pic1 from '../../assets/images/gp-details/rf1.png';
import pic2 from '../../assets/images/gp-details/rf2.png';
import pic3 from '../../assets/images/gp-details/rf3.png';
import pic4 from '../../assets/images/gp-details/rf4.png';
import RFGraphics from './rf-graphics';

const ReportFormatter = () => {
  const title = 'Report Formatter';
  const description = 'ReportFormatter memungkinkan Anda menambahkan gambar dan tabel dengan mudah, serta mengatur caption dan ukuran secara otomatis agar tampil sempurna. Dengan fitur auto-formatting, format dokumen akan disesuaikan sesuai standar akademis, memastikan hasil akhir yang flawless. Anda juga bisa mengunduh dokumen dalam format PDF atau .docx, memberikan fleksibilitas untuk pengeditan tambahan sebelum finalisasi.';

  return (
    <div className="relative gp-details px-6 py-1">
      <RFGraphics />
      <SectionTitle
      title = {title}
      description={description} />

      <Preview 
      pic1={pic1}
      pic2={pic2}
      pic3={pic3}
      pic4={pic4}/>

    <TeamRF title={title}/>

    </div>
  );
};

export default ReportFormatter;

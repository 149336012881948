import React from 'react';
import TeamMemberCard from './team-member-card';
import Nathanael from '../../assets/images/gp-details/Nathaniel.png';
import Priskila from '../../assets/images/gp-details/Priskila.png';
import Claisty from '../../assets/images/gp-details/Claisty.png';
import Alex from '../../assets/images/gp-details/Alex.png';

// Define team members with Nathanael's image as the default for those without custom images
const teamMembers = [
  { name: 'Jeremy Wowiling', role: 'Dev Lead', image: Nathanael }, // Top row, center
  { name: 'Elsa Siwy', role: 'Anggota', image: Nathanael }, // Row 2, Position 1
  { name: 'Nathanael Tumurang', role: 'Anggota', image: Nathanael }, // Row 2, Position 2
  { name: 'Priskila Supit', role: 'Anggota', image: Priskila }, // Row 2, Position 3
  { name: 'Claisty Umboh', role: 'Anggota', image: Claisty }, // Row 3, Position 1
  { name: 'Delon Jutan', role: 'Anggota', image: Nathanael }, // Row 3, Position 2
  { name: 'Eden', role: 'Anggota', image: Nathanael }, // Row 3, Position 3
  { name: 'Alexandrio Maleteng', role: 'Anggota', image: Alex }, // Row 4, Position 1
  { name: 'Edward Benedict', role: 'Anggota', image: Nathanael }, // Row 4, Position 3
];

const TeamTechoverse = () => {
  return (
    <div className="flex flex-col items-center gap-10 mt-10">
      <h1 className="text-7xl font-crushed my-12">Techoverse Team</h1>
      
      {/* Grid Layout for Team Members */}
      <div className="grid grid-cols-3 gap-10">
        {/* Top row: Jeremy alone in the center */}
        <div></div> {/* Empty spot for alignment */}
        <TeamMemberCard image={Nathanael} name="Jeremy Wowiling" role="Dev Lead" />
        <div></div> {/* Empty spot for alignment */}

        {/* Second Row: Elsa - Nathanael - Priskila */}
        <TeamMemberCard image={Nathanael} name="Elsa Siwy" role="Anggota" />
        <TeamMemberCard image={Nathanael} name="Nathanael Tumurang" role="Anggota" />
        <TeamMemberCard image={Priskila} name="Priskila Supit" role="Anggota" />

        {/* Third Row: Claisty - Delon - Eden */}
        <TeamMemberCard image={Claisty} name="Claisty Umboh" role="Anggota" />
        <TeamMemberCard image={Nathanael} name="Delon Jutan" role="Anggota" />
        <TeamMemberCard image={Nathanael} name="Eden" role="Anggota" />

        {/* Fourth Row: Alexandrio - Empty - Edward */}
        <div className='col-span-3 flex justify-center gap-10'>
          <TeamMemberCard image={Alex} name="Alexandrio Maleteng" role="Anggota" />
          <TeamMemberCard image={Nathanael} name="Edward Benedict" role="Anggota" />
        </div>
      </div>
    </div>
  );
};

export default TeamTechoverse;

import React from 'react';
import TeamMemberCard from './team-member-card';
import Faithful from '../../assets/images/projects/faithful.png';
import Marco from '../../assets/images/gp-details/Marco.png';

const TeamCrypnity = ({ title }) => {  // Destructure 'title' here
  return (
    <div className="flex flex-col items-center gap-10 mt-10">
      <h1 className="text-7xl font-crushed my-12">{title} Team</h1>
      <TeamMemberCard
        image={Faithful}
        name="Faithful Mandagi"
        role="Dev Lead"
      />
      <TeamMemberCard
        image={Marco}
        name="Alfonsus Marco"
        role="Anggota"
      />
    </div>
  );
};

export default TeamCrypnity;

import React from 'react';
import orangeCircle from '../../../assets/images/landing-page/techofest-members/orangecircle.png';
import greenCircle from '../../../assets/images/landing-page/gp-members/greenCircle.png';
import longStrip from '../../../assets/images/landing-page/gp-members/long-strip.png';
import blueCircle from '../../../assets/images/landing-page/hero-section/half-circle-blue.png';
import redCircle from '../../../assets/images/landing-page/hero-section/red-circle.png';
import yellowCircle from '../../../assets/images/landing-page/hero-section/yellow-circle.png';
import greenishStar from '../../../assets/images/landing-page/our-project/greenish-star.png';
import softToscaStar from '../../../assets/images/landing-page/hero-section/soft-tosca-star.png';
import softBlueStar from '../../../assets/images/landing-page/hero-section/soft-blue-star.png';

const DecorativeGraphics
 = () => (
  <div className="absolute inset-0 pointer-events-none z-0">
    <img 
      src= {longStrip} 
      alt="Long Strip"
      className='absolute top-[12%] left-[12%] h-[60%] w-56 z-10' />

    {/* Green Circle */}
    <img 
    src={greenCircle} 
    alt="Green Circle"
    className='absolute w-20 top-[17%] right-[40%] rotate-3' 
    />
    
    {/* Orange Circle */}
    <img 
    src={orangeCircle} 
    alt="Orange Circle"
    className='absolute w-16 right-0 top-28 rotate-180 z-10' 
    />
    
    {/* Blue Circle */}
    <img 
    src={blueCircle} 
    alt="Blue Circle"
    className='absolute w-36 right-[-2.7%] top-20 rotate-90 z-0' 
    />
    
    {/* Red Circle */}
    <img 
    src={redCircle} 
    alt="Red Circle"
    className='absolute w-36 left-[9%] bottom-[14%] z-0' 
    />
    
    {/* Yellow Circle */}
    <img 
    src={yellowCircle} 
    alt="Yellow Circle"
    className='absolute w-36 right-[10%] bottom-[30%] z-0' 
    />
    
    {/* Greenish Star */}
    <img 
    src={greenishStar} 
    alt="Greenish Star"
    className='absolute w-6 left-[30%] top-[40%]' 
    />
    
    {/* Soft Tosca Star */}
    <img 
    src={softToscaStar} 
    alt="Soft Tosca Star"
    className='absolute w-6 left-[37%] top-[19%]' 
    />
    
    {/* Soft Blue Star */}
    <img 
    src={softBlueStar} 
    alt="Soft Blue Star"
    className='absolute w-8 right-[25%] top-[35%]' 
    />
  </div>
);

export default DecorativeGraphics
;

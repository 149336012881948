import React from 'react';
import starCard from '../../../assets/images/landing-page/techofest-members/star-card.png';
import sunCard from '../../../assets/images/landing-page/techofest-members/suncard.png';

const PersonCard = ({ name, role, image }) => {
  return (
    <div className="relative bg-gray-100 rounded-[50px] shadow-md py-5 px-0 flex flex-col items-center space-y-3 w-[300px] h-[450px]">      
      {/* Decors for the card */}
      <img 
      src= {starCard}
      alt="Star Card"
      className='absolute w-6 top-[27%] left-[6%]' 
      />

      <img 
      src= {sunCard}
      alt="Sun Card"
      className='absolute w-6 bottom-[25%] right-[10%]' 
      />

      <img
        src={image}
        alt={name}
        className="w-fit h-80 object-cover mx-0"
      />
      <h2 className="text-xl font-semibold font-montserrat">{name}</h2>
      <p className="text-base text-gray-500 font-montserrat w-[auto] text-center">{role}</p>
    </div>
  );
};

export default PersonCard;

import React from 'react';
import TeamMemberCard from './team-member-card';
import Rafael from '../../assets/images/gp-details/Rafael.png';
import Clifford from '../../assets/images/gp-details/Clifford.png';

// Define team members with images
const teamMembers = [
  { name: 'Patrick Gustavo', role: 'Dev Lead', image: Rafael }, 
  { name: 'Rafael Lalujan', role: 'Anggota', image: Rafael },
  { name: 'Charles William', role: 'Anggota', image: Rafael },
  { name: 'Clifford Mandagi', role: 'Anggota', image: Clifford },
  { name: 'Xavier Harahap', role: 'Anggota', image: Clifford },
];

const TeamRF = () => {
  return (
    <div className="flex flex-col items-center gap-10 mt-10">
      <h1 className="text-7xl font-crushed my-12">Report Formatter Team</h1>
      
      {/* Grid Layout for Team Members */}
      <div className="grid grid-cols-3 gap-10">
        
        <div></div> {/* Empty spot for alignment */}
        <TeamMemberCard image={Rafael} name="Patrick Gustavo" role="Dev Lead" />
        <div></div> {/* Empty spot for alignment */}

        <TeamMemberCard image={Rafael} name="Rafael Lalujan" role="Anggota" />
        <TeamMemberCard image={Rafael} name="Charles William" role="Anggota" />
        <TeamMemberCard image={Clifford} name="Clifford Mandagi" role="Anggota" />

        <div className="col-span-3 flex justify-center">
          <TeamMemberCard image={Clifford} name="Xavier Harahap" role="Anggota" />
        </div>
      </div>
    </div>
  );
};

export default TeamRF;
